/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $BlockedNumberIndexRequest = {
    properties: {
        phoneNumber: {
            type: 'string',
        },
        search: {
            type: 'string',
        },
        isActive: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
import {
	RateCenterIndexResponse,
	RateableJurisdictions,
	SipCallSimulatorProfileRequest,
	SubscriptionIndexResponse
} from 'RtModels';
import { CustomerTerminationSubscriptionSelect } from 'RtUi/app/rtSip/CallSimulator/lib/controls/CustomerTerminationSubscriptionSelect';
import { CallSimulatorGrid } from 'RtUi/app/rtSip/CallSimulator/lib/grid/CallSimulatorGrid';
import { useGetCallSimulator } from 'RtUi/app/rtSip/CallSimulator/lib/services';
import { RateCenterSelect } from 'RtUi/app/rtSip/MarginSummaryReport/lib/controls/RateCenterSelect';
import { useGetRoutingRule } from 'RtUi/app/rtSip/RoutingRules/lib/services';
import { InputFormControl } from 'RtUi/components/form/InputFormControl';
import { useUrlSearchParams } from 'RtUi/components/hooks/useUrlSearchParams';
import { Loading } from 'RtUi/components/ui/Loading';
import { RtUiForm } from 'RtUi/components/ui/RtUiForm';
import { isNil, omitBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export interface ICallSimulatorProfileProps {
	subscriptionId: number;
	ratedNumber: string;
	jurisdictionNumber?: string;
}

export const CallSimulatorProfile = ({
	subscriptionId,
	ratedNumber,
	jurisdictionNumber
}: ICallSimulatorProfileProps) => {
	const params = useMemo(
		() =>
			omitBy<SipCallSimulatorProfileRequest>(
				{
					subscriptionId,
					ratedPn: ratedNumber,
					jurisPn: jurisdictionNumber
				},
				isNil
			),
		[subscriptionId, ratedNumber, jurisdictionNumber]
	);

	const { data, isFetching: isLoading } = useGetCallSimulator(
		params as SipCallSimulatorProfileRequest
	);
	const { data: routingRule } = useGetRoutingRule(
		data?.routingRuleId ?? 0,
		data?.routingRuleId !== undefined
	);
	const [loadedSubscription, setLoadedSubscription] =
		useState<SubscriptionIndexResponse>();
	const [rateCenterRated, setRateCenterRated] =
		useState<RateCenterIndexResponse>();
	const [rateCenterJurisdiction, setRateCenterJurisdiction] =
		useState<RateCenterIndexResponse>();
	const navigate = useNavigate();
	const { setUrlSearchParams } = useUrlSearchParams();

	useEffect(() => {
		setUrlSearchParams(params);
	}, [params, setUrlSearchParams]);

	if (isLoading) {
		return <Loading />;
	}

	const redirectToRoutingRule = (routingRuleId: number) => {
		navigate(`/rtSip/routingRules/${routingRuleId}`);
	};

	return (
		<>
			<RtUiForm
				className="mb-3"
				hideButtons
				createMode={false}
				displayMode={true}
				isInline
			>
				<Row>
					<Col lg={6}>
						<fieldset className="card card-body mb-0">
							<legend className="card-header">
								<h6>Subscription</h6>
							</legend>
							<CustomerTerminationSubscriptionSelect
								label="Summary"
								displayMode
								onChange={(newSub) => {
									setLoadedSubscription(newSub ?? undefined);
								}}
								value={loadedSubscription}
								initialOptionId={data?.rate?.subscriptionId}
							/>
							<InputFormControl
								label="Rated jurisdiction"
								displayMode
								value={String(
									data?.rate.jurisdictionId
										? RateableJurisdictions[data.rate.jurisdictionId]
										: ''
								)}
							/>
							<InputFormControl
								label="Rate"
								displayMode
								value={String(data?.rate.rate)}
							/>
						</fieldset>
					</Col>
					{routingRule && (
						<Col lg={6}>
							<fieldset className="card card-body mb-0">
								<legend className="card-header">
									<h6>Routing rule</h6>
								</legend>
								<div className="d-flex justify-content-between bg-warning">
									<span
										className="d-block fw-semibold"
										style={{ margin: '6px' }}
									>
										Summary
									</span>
									<Button
										variant="link"
										onClick={() =>
											redirectToRoutingRule(routingRule.routingRuleId)
										}
									>
										{routingRule.label}
									</Button>
								</div>
							</fieldset>
						</Col>
					)}
				</Row>
				<Row>
					<Col lg={6}>
						<fieldset className="card card-body mb-0">
							<legend className="card-header">
								<h6>Rated Number</h6>
							</legend>
							<InputFormControl
								label="Number"
								displayMode
								value={ratedNumber}
							/>
							<RateCenterSelect
								label="Rate Center"
								initialOptionId={String(data?.ratedPn.rateCenterId ?? '')}
								value={rateCenterRated}
								displayMode
								onChange={(value) => {
									setRateCenterRated(value);
								}}
							/>
							<InputFormControl
								label="LATA"
								displayMode
								value={String(data?.ratedPn.lata ?? '')}
							/>
							<InputFormControl
								label="OCN"
								displayMode
								value={String(data?.ratedPn.ocn ?? '')}
							/>
							<InputFormControl
								label="Location"
								displayMode
								value={[data?.ratedPn.city, data?.ratedPn.state]
									.filter((val) => val)
									.join(', ')}
							/>
						</fieldset>
					</Col>
					<Col lg={6}>
						<fieldset className="card card-body mb-0">
							<legend className="card-header">
								<h6>Jurisdiction Number</h6>
							</legend>
							<InputFormControl
								label="Number"
								displayMode
								value={jurisdictionNumber || ''}
							/>
							<RateCenterSelect
								label="Rate Center"
								initialOptionId={String(data?.jurisPn.rateCenterId ?? '')}
								value={rateCenterJurisdiction}
								displayMode
								onChange={(value) => {
									setRateCenterJurisdiction(value);
								}}
							/>
							<InputFormControl
								label="LATA"
								displayMode
								value={String(data?.jurisPn.lata ?? '')}
							/>
							<InputFormControl
								label="OCN"
								displayMode
								value={String(data?.jurisPn.ocn ?? '')}
							/>
							<InputFormControl
								label="Location"
								displayMode
								value={[data?.jurisPn.city, data?.jurisPn.state]
									.filter((val) => val)
									.join(', ')}
							/>
						</fieldset>
					</Col>
				</Row>
			</RtUiForm>
			<CallSimulatorGrid
				srcUseRate={data?.rate.rate || 0}
				rateables={data?.rateables || []}
			/>
		</>
	);
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $TaskIndexRequest = {
    properties: {
        entityId: {
            type: 'string',
        },
        respOrgId: {
            type: 'string',
        },
        numberGroupId: {
            type: 'number',
        },
        taskTypeIds: {
            type: 'Array',
        },
        referenceKey: {
            type: 'string',
        },
        scheduledAfterTs: {
            type: 'Date',
            format: 'date-time',
        },
        scheduledBeforeTs: {
            type: 'Date',
            format: 'date-time',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
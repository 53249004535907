/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $MarginSummaryIndexRequest = {
    properties: {
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        directionId: {
            type: 'CallflowDirectionId',
            isRequired: true,
        },
        costAccountIds: {
            type: 'Array',
        },
        costSubscriptionIds: {
            type: 'Array',
        },
        revenueAccountIds: {
            type: 'Array',
        },
        revenueSubscriptionIds: {
            type: 'Array',
        },
        countryIsoIds: {
            type: 'Array',
        },
        rateCenterIds: {
            type: 'Array',
        },
        columns: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	GuardianAlertIndexResponse,
	GuardianAlertProfileRequest,
	GuardianAlertProfileResponse,
	GuardianAlertRecipient,
	GuardianAlertRecipientIndexRequest,
	GuardianAlertRuleIndexRequest,
	GuardianAlertScheduleIndexRequest,
	GuardianAlertScheduleIndexResponse,
	GuardianAlertTypeIndexRequest,
	GuardianAlertTypeIndexResponse
} from 'RtModels';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';
import { useQuery } from 'react-query';

const fetchGuardianAlertRule = async (
	urlParams: GuardianAlertProfileRequest
): Promise<GuardianAlertProfileResponse | undefined> => {
	return handleGetRequest<GuardianAlertProfileResponse>(
		RtVueApiRoutes.GuardianAlerts.Profile,
		{
			urlParams
		}
	);
};

export const useGetGuardianAlertRule = (
	urlParams: GuardianAlertProfileRequest,
	enabled: boolean = true
) => {
	return useQuery<GuardianAlertProfileResponse | undefined, Error>(
		[`getGuardianAlertRule`, urlParams],
		() => fetchGuardianAlertRule(urlParams),
		{ enabled }
	);
};

const fetchGuardianAlertRuleTypes = async (
	queryParams?: GuardianAlertTypeIndexRequest
): Promise<GuardianAlertTypeIndexResponse[]> => {
	return handleGetRequest<GuardianAlertTypeIndexResponse[]>(
		RtVueApiRoutes.GuardianAlertTypes.Index,
		{
			queryParams
		}
	);
};

export const useGetGuardianAlertRuleType = (
	alertTypeId: number,
	enabled: boolean = true
) => {
	return useQuery<GuardianAlertTypeIndexResponse | undefined, Error>(
		[`useGetGuardianAlertRuleType`],
		async () => {
			const data = await fetchGuardianAlertRuleTypes();
			return data.find((alertType) => alertType.alertTypeId === alertTypeId);
		},
		{ enabled }
	);
};

const fetchGuardianAlertRules = async (
	queryParams?: Partial<GuardianAlertRuleIndexRequest>
): Promise<FullResponse<GuardianAlertIndexResponse[]>> => {
	return handleGetRequest<GuardianAlertIndexResponse[], true>(
		RtVueApiRoutes.GuardianAlerts.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetGuardianAlertRules = (
	resourceParams?: Partial<GuardianAlertRuleIndexRequest>
) => {
	const cacheKey = resourceParams ? JSON.stringify(resourceParams) : '';

	return useQuery<FullResponse<GuardianAlertIndexResponse[]>, Error>(
		[`getGuardianAlertRules${cacheKey}`, cacheKey],
		() => fetchGuardianAlertRules(resourceParams),
		{ enabled: true }
	);
};

const fetchGuardianAlertRecipients = async (
	queryParams?: GuardianAlertRecipientIndexRequest
): Promise<FullResponse<GuardianAlertRecipient[]>> => {
	return handleGetRequest<GuardianAlertRecipient[], true>(
		RtVueApiRoutes.GuardianAlertsRecipients.Index,
		{
			queryParams,
			includeFullResponse: true
		}
	);
};

export const useGetGuardianAlertRecipients = (
	resourceParams?: GuardianAlertRecipientIndexRequest
) => {
	const cacheKey = resourceParams ? JSON.stringify(resourceParams) : '';

	return useQuery<FullResponse<GuardianAlertRecipient[]>, Error>(
		[`getGuardianAlertRecipients${cacheKey}`, cacheKey],
		() => fetchGuardianAlertRecipients(resourceParams),
		{ enabled: true }
	);
};

const fetchGuardianAlertSchedules = async (
	urlParams?: GuardianAlertScheduleIndexRequest
): Promise<FullResponse<GuardianAlertScheduleIndexResponse[]>> => {
	return handleGetRequest<GuardianAlertScheduleIndexResponse[], true>(
		RtVueApiRoutes.GuardianAlertsSchedules.Index,
		{
			urlParams,
			includeFullResponse: true
		}
	);
};

export const useGetGuardianAlertSchedules = (
	resourceParams?: GuardianAlertScheduleIndexRequest
) => {
	const cacheKey = resourceParams ? JSON.stringify(resourceParams) : '';

	return useQuery<FullResponse<GuardianAlertScheduleIndexResponse[]>, Error>(
		[`getGuardianAlertSchedules${cacheKey}`, cacheKey],
		() => fetchGuardianAlertSchedules(resourceParams)
	);
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $FullSummaryIndexRequest = {
    properties: {
        startTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        timeslot: {
            type: 'FullSummaryTimeDisplay',
            isRequired: true,
        },
        callingPartyStd: {
            type: 'string',
        },
        callingPartyStdContains: {
            type: 'string',
        },
        callingPartyCountryIsoIds: {
            type: 'Array',
        },
        callingPartyRateCenterIds: {
            type: 'Array',
        },
        dialedNumberStd: {
            type: 'string',
        },
        dialedNumberStdContains: {
            type: 'string',
        },
        dialedNumberCountryIsoIds: {
            type: 'Array',
        },
        dialedNumberRateCenterIds: {
            type: 'Array',
        },
        ingressRevenueAccountIds: {
            type: 'Array',
        },
        ingressRevenueSubscriptionIds: {
            type: 'Array',
        },
        egressRevenueAccountIds: {
            type: 'Array',
        },
        egressRevenueSubscriptionIds: {
            type: 'Array',
        },
        ingressCostAccountIds: {
            type: 'Array',
        },
        ingressCostSubscriptionIds: {
            type: 'Array',
        },
        egressCostAccountIds: {
            type: 'Array',
        },
        egressCostSubscriptionIds: {
            type: 'Array',
        },
        columns: {
            type: 'Array',
        },
        inMin: {
            type: 'number',
        },
        inMax: {
            type: 'number',
        },
        egMin: {
            type: 'number',
        },
        egMax: {
            type: 'number',
        },
        inCon: {
            type: 'number',
        },
        egCon: {
            type: 'number',
        },
        serverIds: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrExportTypeIndexRequest = {
    properties: {
        serviceId: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
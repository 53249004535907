import { NumberIndexResponse } from 'RtModels';
import { RtxUiPageSizes } from 'RtUi/RtxUiConstants';
import {
	ApplicationNotificationTypes,
	ApplicationNotifications,
	INotification
} from 'RtUi/app/ApplicationShell/lib/components/ApplicationNotifications';
import {
	IDidNumberIndex,
	IDidNumberIndexRequest
} from 'RtUi/app/rtDid/Numbers/lib/resources/DidNumbersResource';
import {
	fetchDidNumbersCsv,
	useGetDidNumbers
} from 'RtUi/app/rtDid/Numbers/lib/services';
import { RtUiRouter } from 'RtUi/components/containers/lib/RtUiRouter';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getDidNumbersColumns } from 'RtUi/components/data/DataGrid/configurations/rtDid/numbers';
import { FileUtils } from 'RtUi/utils/file/FileUtils';
import { FC, useMemo } from 'react';

interface IDidNumbersGridProps {
	onRowClick?: (row: IDidNumberIndex) => void;
	resourceParams?: IDidNumberIndexRequest;
	autoFocusFilter?: boolean;
	disableReferences?: RtUiRouter[];
}

export const DidNumbersGrid: FC<
	React.PropsWithChildren<IDidNumbersGridProps>
> = ({ resourceParams, onRowClick }) => {
	const { data, isFetching: isLoading } = useGetDidNumbers(resourceParams);
	const columns = useMemo(() => getDidNumbersColumns(), []);

	return (
		<DataGrid<NumberIndexResponse>
			data={data?.data}
			columns={columns}
			totalRows={data?.totalCount}
			loading={isLoading}
			onRowClick={onRowClick}
			customExportToCsv={async () => {
				const notification: INotification = {
					message:
						'The export will be downloaded once completed. Depending on the number of records it may take several minutes. You may navigate to other parts of the app.',
					title: 'Export Has Started',
					notificationId: `DidNumber-${Math.random()}`,
					notificationTypeId: ApplicationNotificationTypes.Info,
					effectiveTs: new Date(),
					expirationTs: new Date(),
					isUrgent: 0
				};

				ApplicationNotifications.openNotification(notification, {
					autoClose: 10_000
				});

				const csvStr = await fetchDidNumbersCsv({
					page: 0,
					pageSize: RtxUiPageSizes.HardAll,
					...resourceParams
				});
				const fileUtils = new FileUtils();
				const blob = fileUtils.createCsvBlob(csvStr);
				await fileUtils.saveBlobAs(blob, 'DidNumbers.csv');
			}}
			pageName={'rtDid_numbers'}
		/>
	);
};

import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';
import { RtVueApiRoutes } from 'RtExports/routes';
import {
	FileLogIndexRequest,
	FileLogTotalsIndexRequest,
	FileLogTotalsIndexResponse,
	GuardianFileLog
} from 'RtModels';
import { FileLogRouter } from 'RtUi/app/rtVue/FileLog/FileLog.router';
import { CustomFileLogButton } from 'RtUi/app/rtVue/FileLog/lib/components/CustomFileLogButton';
import { useGetFileLogs } from 'RtUi/app/rtVue/FileLog/lib/service';
import DataGrid from 'RtUi/components/data/DataGrid';
import { getFileLogsColumns } from 'RtUi/components/data/DataGrid/configurations/rtVue/fileLogs';
import { RtxUiPageSizes } from 'RtUi/RtxUiConstants';
import {
	FullResponse,
	handleGetRequest
} from 'RtUi/utils/http/AxiosHttpRequest';

interface IFileLogGridProps<T extends FileLogRequest> {
	resourceParams?: T;
}

export type FileLogRequest = FileLogIndexRequest | FileLogTotalsIndexRequest;
export type FileLogResponse<T extends FileLogRequest> =
	T extends FileLogIndexRequest ? GuardianFileLog : FileLogTotalsIndexResponse;

export const FileLogGrid = <T extends FileLogRequest>({
	resourceParams
}: IFileLogGridProps<T>) => {
	const [rowSelection, setRowSelection] = useState<Array<FileLogResponse<T>>>(
		[]
	);
	const {
		data: fileLogData,
		isFetching: isLoading,
		refetch
	} = useGetFileLogs<T>(resourceParams);

	const fetchFileLogs = async (
		queryParams?: FileLogIndexRequest
	): Promise<FullResponse<GuardianFileLog[]>> => {
		return handleGetRequest(RtVueApiRoutes.FileLogs.Index, {
			queryParams,
			includeFullResponse: true
		});
	};

	const FullResultsReq = async () => {
		const params = { ...resourceParams, pageSize: RtxUiPageSizes.HardAll };
		const data = (await fetchFileLogs(params)) as any;
		return data.data;
	};

	const isFileLogTotals = useMemo(
		() => (resourceParams?.fileStreamIds ?? []).length > 1,
		[resourceParams]
	);

	const columns = useMemo(
		() => getFileLogsColumns<T>(isFileLogTotals),
		[isFileLogTotals]
	);

	const renderCustomButton = () => {
		if (isEmpty(rowSelection)) {
			return <></>;
		}

		return (
			<CustomFileLogButton
				selected={rowSelection}
				refetch={refetch}
				setRowSelected={setRowSelection}
			/>
		);
	};

	return (
		<DataGrid<FileLogResponse<T>>
			data={fileLogData?.data}
			columns={columns}
			pageName={'configureRateSheet-fileLog'}
			enableExternalExport
			getExternalExportData={FullResultsReq}
			router={!isFileLogTotals ? FileLogRouter : undefined}
			loading={isLoading}
			headerAction={renderCustomButton}
			enableRowSelection={!isFileLogTotals}
			selectedRows={rowSelection}
			onChangeSelectedRows={setRowSelection}
			totalRows={fileLogData?.totalCount}
		/>
	);
};

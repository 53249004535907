import { RoutingGroupProfileResponse, RoutingTemplateTypes } from 'RtModels';
import { NumberGrid } from 'RtUi/app/rt800/Numbers/lib/grids/NumberGrid';
import { useGetNumbers } from 'RtUi/app/rt800/Numbers/lib/services';
import { useGetRoutingGroup } from 'RtUi/app/rt800/RoutingGroups/lib/services';
import { TasksGrid } from 'RtUi/app/rt800/Tasks/lib/grids/TasksGrid';
import { TemplateRouter } from 'RtUi/app/rt800/Templates/Template.router';
import { useIdParam } from 'RtUi/components/hooks/useIdParam';
import { DashboardStat } from 'RtUi/components/ui/DashboardStat';
import { Loading } from 'RtUi/components/ui/Loading';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { UserActions } from 'RtUi/state/actions/user';
import clsx from 'clsx';
import { subYears } from 'date-fns';
import { useState } from 'react';
import {
	Alert,
	Badge,
	Button,
	Card,
	Col,
	ListGroup,
	Modal,
	OverlayTrigger,
	Row,
	Tooltip
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { RoutingGroupRouter } from './RoutingGroup.router';
import { RoutingGroupEditor } from './lib/forms/RoutingGroupEditor';
import { RoutingGroupResource } from './lib/resources/RoutingGroupResource';

export const RoutingGroupProfileContainer = () => {
	const [activateModalIsOpen, setActivateModalIsOpen] = useState<boolean>();
	const [activeTemplate, setActiveTemplate] =
		useState<RoutingGroupProfileResponse['templates'][0]>();
	const [activating, setActivating] = useState<boolean>();
	const [activateError, setActivateError] = useState<string>();
	const [tabs, activeTab, setActiveTab] = useTabs(
		RoutingGroupRouter.getProfileTabs()
	);
	const routingGroupId = useIdParam();
	const {
		data: routingGroup,
		isFetching,
		refetch
	} = useGetRoutingGroup({ routingGroupId: Number(routingGroupId) });
	const navigate = useNavigate();

	const workingTemplate = routingGroup?.templates.find(
		(t) => t.routingTemplateTypeId === RoutingTemplateTypes.Working
	);

	const { data: { data: workingTemplateNumber = [] } = {} } = useGetNumbers(
		{ templateName: workingTemplate?.templateName },
		workingTemplate !== undefined
	);

	if (isFetching) {
		return <Loading />;
	}

	if (!routingGroup) {
		return <p>Not Found</p>;
	}

	const canManage = UserActions.hasAccessToRespOrg(routingGroup);

	const defaultTemplate = routingGroup.templates.find(
		(t) => t.routingTemplateTypeId === RoutingTemplateTypes.Default
	);
	const altTemplates = routingGroup.templates.filter(
		(t) => t.routingTemplateTypeId === RoutingTemplateTypes.Alternate
	);
	const activatedTemplate = routingGroup.templates.find(
		(t) => t.isCurrent === 1
	);

	const templateName = workingTemplate
		? workingTemplate.templateName
		: undefined;

	const resourceParams = { templateName };
	const isInDisasterRecoveryMode =
		defaultTemplate && defaultTemplate.isCurrent === 0;

	const openActivateModal = (
		activeTemplate: RoutingGroupProfileResponse['templates'][0]
	) => {
		setActivateModalIsOpen(true);
		setActiveTemplate(activeTemplate);
	};

	const getActivateButton = (
		template: RoutingGroupProfileResponse['templates'][0]
	) => {
		const isCurrent = template.isCurrent === 1;

		if (isCurrent) {
			return <div style={{ width: '100px' }} className="me-3"></div>;
		}
		const isLocked = template.isLocked === 0;
		const isWorking =
			template.routingGroupTemplateId === RoutingTemplateTypes.Working;
		const canActivate = canManage && isLocked && !isCurrent && !isWorking;

		return (
			<Button
				size="sm"
				className="me-2 ms-4"
				onClick={() => openActivateModal(template)}
				disabled={!canActivate}
			>
				Activate
			</Button>
		);
	};

	const renderTemplate = (
		template?: RoutingGroupProfileResponse['templates'][0]
	) => {
		if (!template) {
			return null;
		}

		const isCurrent = template.isCurrent === 1;
		const isWorking =
			template.routingGroupTemplateId === RoutingTemplateTypes.Working;
		const isActive = template.isActive === 1;
		const isValidCpr = template.isValidCpr === 1;
		const hasErrors = template.hasErrors === 1;

		let label = template.label;
		if (label !== template.templateName) {
			label += ` (${template.templateName})`;
		}

		let errorMessage = '';
		if (!isValidCpr) {
			errorMessage = 'No valid CPR';
		}
		if (hasErrors) {
			errorMessage = 'Template has errors';
		}
		if (!isActive) {
			errorMessage = 'Template Inactive';
		}

		if (errorMessage) {
			return (
				<section className="d-flex align-items-center justify-content-between">
					<div className="mr-auto">
						<Link to={TemplateRouter.getProfileRoute(template.templateName)}>
							{label}
						</Link>
						<Badge bg={'danger'} text={'white'} className="ms-2">
							{errorMessage}
						</Badge>
					</div>
				</section>
			);
		}

		return (
			<section className="d-flex align-items-center justify-content-between">
				<div className="mr-auto">
					<Link to={TemplateRouter.getProfileRoute(template.templateName)}>
						{label}
					</Link>
					<Badge
						bg={isCurrent ? 'success' : 'light'}
						text={isCurrent ? undefined : 'dark'}
						className="ms-2"
					>
						{isWorking ? 'Working' : isCurrent ? 'Active' : 'Not Active'}
					</Badge>
				</div>
				{getActivateButton(template)}
			</section>
		);
	};

	const submitActivateModal = () => {
		const routingGroupResource = new RoutingGroupResource();
		setActivateError(undefined);

		if (activeTemplate) {
			const { routingGroupId, routingGroupTemplateId } = activeTemplate;

			setActivating(true);

			routingGroupResource
				.activate(routingGroupId, routingGroupTemplateId)
				.then((task) => navigate(`/rt800/tasks/${task.taskId}`))
				.catch((activateErrors: string[]) => {
					const activateError = activateErrors.join(', ');

					setActivateError(activateError);
				})
				.finally(() => setActivating(false));
		}
	};

	const closeActivateModal = () => {
		setActivateModalIsOpen(true);
		setActiveTemplate(undefined);
		setActivateError(undefined);
	};

	const onUpdateSuccess = () => {
		refetch();
		setActiveTab(tabs.Profile.header);
	};

	return (
		<TabbedLayout<RoutingGroupProfileResponse>
			router={RoutingGroupRouter}
			profile={routingGroup}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Routing}>
				{isInDisasterRecoveryMode && (
					<Alert variant="danger">
						Routing group is currently in disaster recovery mode. An alternate
						template is the working routing profile.
					</Alert>
				)}
				<Row>
					<Col lg={4} className="mb-5">
						<DashboardStat
							header="Routing Mode"
							stat={
								isInDisasterRecoveryMode
									? 'Disaster Recovery'
									: 'Normal Routing'
							}
							tooltip={
								isInDisasterRecoveryMode
									? 'Routing group is currently in disaster recovery mode. An alternate template is the working routing profile.'
									: 'The default routing profile is the activated routing profile.'
							}
							icon={
								isInDisasterRecoveryMode ? 'exclamation-circle' : 'check-circle'
							}
							iconColor={isInDisasterRecoveryMode ? 'danger' : 'success'}
						/>
					</Col>
					<Col lg={4} className="mb-5">
						{activatedTemplate && (
							<DashboardStat
								header="Activated Routing Profile"
								stat={
									<span className="d-flex-inline justify-content-start">
										<Link
											to={TemplateRouter.getProfileRoute(
												activatedTemplate.templateName
											)}
										>
											{activatedTemplate.templateName}
										</Link>
										<small
											className={clsx('px-2 ms-2 rounded', {
												'bg-light':
													activatedTemplate.routingTemplateTypeId ===
													RoutingTemplateTypes.Default,
												'bg-danger text-white':
													activatedTemplate.routingTemplateTypeId !==
													RoutingTemplateTypes.Default
											})}
										>
											{
												RoutingTemplateTypes[
													activatedTemplate.routingTemplateTypeId
												]
											}
										</small>
									</span>
								}
								tooltip="Currently syncing to working routing profile"
							/>
						)}
					</Col>
					<Col lg={4} className="mb-5">
						<DashboardStat
							header={`Working Routing Profile (${workingTemplateNumber.length.toLocaleString()} Numbers)`}
							stat={
								<Link
									to={TemplateRouter.getProfileRoute(
										workingTemplate!.templateName
									)}
								>
									{workingTemplate!.templateName}
								</Link>
							}
							tooltip="The working routing profile is where Toll-Free numbers are assigned, and specifies the current routing rules for those numbers."
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<Card className="mb-5">
							<Card.Header>
								<h6 className="mb-0 d-flex justify-content-between align-items-center">
									<div className="mr-auto">
										Default Routing Profile
										<Badge bg="light" text="dark" className="ms-2">
											Normal Routing
										</Badge>
									</div>
									<div className="align-self-end">
										<OverlayTrigger
											overlay={(props) => (
												<Tooltip id="defaultTemplateInfo-tooltip" {...props}>
													The default routing profile contains the routing rules
													that are applied to the working routing profile under
													normal operations.
												</Tooltip>
											)}
										>
											{({ ref, ...triggerHandler }) => (
												<i
													ref={ref}
													{...triggerHandler}
													className="fas fa-fw fa-info-circle text-muted me-1"
													id="defaultTemplateInfo"
												/>
											)}
										</OverlayTrigger>
									</div>
								</h6>
							</Card.Header>
							{activatedTemplate && (
								<ListGroup
									variant="flush"
									className="list-group-info-highlight"
								>
									<ListGroup.Item
										active={
											defaultTemplate?.templateName ===
											activatedTemplate.templateName
										}
									>
										{renderTemplate(defaultTemplate)}
									</ListGroup.Item>
								</ListGroup>
							)}
						</Card>
						{altTemplates.length > 0 && (
							<Card>
								<Card.Header>
									<h6 className="mb-0 d-flex justify-content-between align-items-center">
										<span>
											Alternate Routing Profiles
											<Badge bg="light" text="dark" className="ms-2">
												Disaster Recover Mode
											</Badge>
										</span>
										<div className="align-self-end">
											<OverlayTrigger
												overlay={(props) => (
													<Tooltip
														id="alternateTemplateInfo-tooltip"
														{...props}
													>
														Alternate routing profiles contain routing rules
														that can be applied to the working routing profile
														when default routing is not desired, such as during
														carrier outages or scheduled maintenance windows.
													</Tooltip>
												)}
											>
												{({ ref, ...triggerHandler }) => (
													<i
														ref={ref}
														{...triggerHandler}
														className="fas fa-fw fa-info-circle text-muted me-1"
														id="alternateTemplateInfo"
													/>
												)}
											</OverlayTrigger>
										</div>
									</h6>
								</Card.Header>
								<ListGroup
									variant="flush"
									className="list-group-info-highlight"
								>
									{altTemplates.map((template) => (
										<ListGroup.Item
											key={template.templateName}
											className={clsx(
												//@ts-ignore
												'bg-warn text-light' &&
													template.templateName ===
														activatedTemplate?.templateName
											)}
										>
											{renderTemplate(template)}
										</ListGroup.Item>
									))}
								</ListGroup>
							</Card>
						)}
					</Col>
				</Row>
				{activeTemplate && (
					<Modal
						show={activateModalIsOpen}
						size="lg"
						onHide={() => setActivateModalIsOpen((currState) => !currState)}
					>
						<Modal.Header>Routing Activation</Modal.Header>
						{!activateError && (
							<Alert variant="info" className="mb-0 alert-sm">
								<i className="fas fa-fw fa-info-circle" />
								<span>
									&nbsp;&nbsp;Routing can only be changed once every 15 minutes.
								</span>
							</Alert>
						)}
						<Modal.Body>
							<article>
								Are you sure you wish to activate routing from template&nbsp;
								{activeTemplate.templateName}?
							</article>
						</Modal.Body>
						{activateError && (
							<Alert variant="danger" className="mb-0">
								<i className="fas fa-fw fa-exclamation-triangle" />
								<span>&nbsp;Cannot Activate: {activateError}</span>
							</Alert>
						)}
						<Modal.Body className="text-end">
							{!activateError && (
								<Button
									variant="primary"
									disabled={activating}
									onClick={() => submitActivateModal()}
								>
									{activating ? (
										<>
											<i className="fas fa-fw fa-cog fa-spin" />
											<span>&nbsp;Activating...</span>
										</>
									) : (
										<>
											<i className="fas fa-fw fa-check" />
											<span>&nbsp;Yes, Activate</span>
										</>
									)}
								</Button>
							)}
							<Button
								variant="link"
								className="text-dark"
								disabled={activating}
								onClick={() => closeActivateModal()}
							>
								{/* <i className="fas fa-fw fa-times" /> */}
								<span>&nbsp;No, Cancel</span>
							</Button>
						</Modal.Body>
					</Modal>
				)}
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Profile}>
				<RoutingGroupEditor
					editMode={routingGroup}
					onUpdateSuccess={() => onUpdateSuccess()}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Numbers}>
				<NumberGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.History}>
				<TasksGrid
					resourceParams={{
						referenceKey: templateName,
						scheduledAfterTs: subYears(new Date(), 3)
					}}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutHelpContainer tab={tabs.Profile.header}>
				<dl>
					<dt>Label</dt>
					<dd className="text-muted mb-2">
						The visible name of the Routing Group.
					</dd>
					<dt>RespOrg</dt>
					<dd className="text-muted mb-2">
						Restricts user access based on permissions.
					</dd>
					<dt>Working Routing Profile</dt>
					<dd className="text-muted mb-2">
						The Template holding numbers managed in this Routing Group.
					</dd>
					<dt>Default Routing Profile</dt>
					<dd className="text-muted mb-2">
						The preferred template routing profile.
					</dd>
					<dt>Alternate Templates</dt>
					<dd className="text-muted mb-2">
						User-defined routing options for activation in a disaster.
					</dd>
				</dl>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

RoutingGroupRouter.setProfileRtUiFunctionalComponent(
	RoutingGroupProfileContainer
);

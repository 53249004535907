import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { FileStreamIndexResponse } from 'RtModels';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { JSONDataGridColumn } from 'RtUi/components/data/DataGrid/columns/JSONDataGridColumn';
import { IsActiveDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IsActiveDataGridColumn';
import { IdDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IdDataGridColumn';
import { SubscriptionDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SubscriptionDataGridColumn';

export const getFileStreamsColumns = (): Array<
	DataGridColumn<FileStreamIndexResponse>
> => [
	IdDataGridColumn({
		accessorKey: 'fileStreamId'
	}),
	DefaultDataGridColumn({
		accessorKey: 'description',
		header: 'Description'
	}),
	DefaultDataGridColumn({
		accessorKey: 'fileLabel',
		header: 'File Label',
		isHidden: true
	}),
	IsActiveDataGridColumn({
		accessorKey: 'isActive'
	}),

	IdDataGridColumn({
		accessorKey: 'fileTypeId',
		header: 'File Type Id',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isDownload',
		header: 'Is Download',
		isHidden: true
	}),

	DefaultDataGridColumn({
		accessorKey: 'downloadRemoteProtocol',
		header: 'Download Remote Protocol',
		isHidden: true
	}),
	JSONDataGridColumn({
		accessorKey: 'downloadRemoteCredentials',
		header: 'Download Remote Credentials',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadRemotePath',
		header: 'Download Remote Path',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'downloadRemoteDelete',
		header: 'Download Remote Delete',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadCompressionType',
		header: 'Download Compression Type',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadFileRegex',
		header: 'Download File Regex',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'downloadLocalPath',
		header: 'Download Local Path',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'workingLocalPath',
		header: 'Working Local Path',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'workingOutputPath',
		header: 'Working Output Path',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'topicRawData',
		header: 'Raw Topic',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'topicRawDataPeakCps',
		header: 'Peak Cps',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'topicTransformedData',
		header: 'Transformed Topic',
		isHidden: true
	}),
	DefaultDataGridColumn({
		accessorKey: 'outputType',
		header: 'Output Type',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isLoadRaw',
		header: 'Is Load Raw',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isNetworkReporting',
		header: 'Network'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isFinancialReporting',
		header: 'Financial'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isFraud',
		header: 'Fraud'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isEnhancedTfReporting',
		header: 'Toll-Free'
	}),

	BooleanDataGridColumn({
		accessorKey: 'isArchived',
		header: 'Archived'
	}),

	SubscriptionDataGridColumn({
		accessorKey: 'origSubscriptionId',
		header: 'Orig Subscription'
	}),

	SubscriptionDataGridColumn({
		accessorKey: 'termSubscriptionId',
		header: 'Term Subscription'
	}),

	// @TODO: Add these to profile and in create/update page
	IntegerDataGridColumn({
		accessorKey: 'origConnectionId',
		header: 'Orig Connection'
	}),

	IntegerDataGridColumn({
		accessorKey: 'termConnectionId',
		header: 'Term Connection'
	}),

	DefaultDataGridColumn({
		accessorKey: 'archiveBucket',
		header: 'Archive Bucket',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'archiveDelay',
		header: 'Archive Delay',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'cdrRetention',
		header: 'CDR Retention',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'reportingRetention',
		header: 'Reporting Retention',
		isHidden: true
	}),
	IntegerDataGridColumn({
		accessorKey: 'archiveRetention',
		header: 'Archive Retention',
		isHidden: true
	}),
	TimeStampDataGridColumn({
		accessorKey: 'createdTs',
		header: 'Created'
	})
	// TimeStampDataGridColumn({
	// 	accessorKey: 'updateTs',
	// 	header: 'Last Updated'
	// })
	// TimeStampDataGridColumn({
	// 	accessorKey: 'pauseTs',
	// 	header: 'Pause'
	// })
];

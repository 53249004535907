import { CprNodeTypeNameMap } from 'RtUi/app/rt800/Cprs/lib/Constants';
import { CprIndexResponse } from 'RtUi/app/rt800/Cprs/lib/grids/CprGrid';
import {
	CprScpsIndexRequest,
	CprScpsIndexResponse
} from 'RtUi/app/rt800/Cprs/lib/grids/ScpGrid';
import { getCprCol } from 'RtUi/app/rt800/Cprs/lib/util';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { CprColDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CprColDataGridColumn';
import { CprLabelNameDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CprLabelNameDataGridColumn';
import { CprLabelRowIndexDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CprLabelRowIndexDataGridColumn';
import { CprLabelValuesDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CprLabelValuesDataGridColumn';
import { CprNodeTypeDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CprNodeTypeDataGridColumn';
import { CprRowIndexDataGridColumn } from 'RtUi/components/data/DataGrid/columns/CprRowIndexDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DelimiterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DelimiterDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { SomosApprovalIndicatorDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosApprovalIndicatorDataGridColumn';
import { SomosCprStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosCprStatus';
import { SomosScpStatusDataGridColumn } from 'RtUi/components/data/DataGrid/columns/SomosScpStatusDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import {
	Cpr,
	CprCol,
	CprLbl,
	CprNodeType,
	CprRow
} from 'Somos/lib/SomosCpr/RtCprV2';

export const getCprColumns = <T extends CprIndexResponse>(
	isNumberCpr: boolean
): Array<DataGridColumn<T>> => {
	const columns: Array<DataGridColumn<T>> = [
		TimeStampDataGridColumn({
			accessorKey: 'effectiveTs',
			header: 'Effective Time'
		})
	];

	if (isNumberCpr) {
		columns.push(
			DefaultDataGridColumn({
				accessorKey: 'templateName',
				header: 'Template Name'
			})
		);
	}

	return [
		...columns,
		DefaultDataGridColumn({
			accessorKey: 'respOrgId',
			header: 'RespOrg'
		}),

		DelimiterDataGridColumn({
			accessorKey: 'interLataCarriers',
			header: 'Carriers',
			delimiter: ','
		}),
		DelimiterDataGridColumn({
			accessorKey: 'areasOfService',
			header: 'AOS',
			delimiter: ','
		}),
		SomosCprStatusDataGridColumn({
			accessorKey: 'cprStatusId',
			header: 'CPR Status'
		}),
		SomosApprovalIndicatorDataGridColumn({
			accessorKey: 'approvalIndicator',
			header: 'Approval'
		}),
		IntegerDataGridColumn({
			accessorKey: 'scpQueuedQty',
			header: 'SCP Queued'
		}),
		IntegerDataGridColumn({
			accessorKey: 'scpFailureQty',
			header: 'SCP Failure'
		}),
		IntegerDataGridColumn({
			accessorKey: 'scpOtherQty',
			header: 'SCP Other'
		}),
		IntegerDataGridColumn({
			accessorKey: 'scpSuccessQty',
			header: 'SCP Success'
		}),
		IntegerDataGridColumn({
			accessorKey: 'scpTotalQty',
			header: 'SCP Total'
		})
	];
};

export const getCprProfileColumns = (
	cprCols: CprCol[],
	displayMode: boolean,
	onAddCprNode: (decisionTypes: CprNodeType) => void
): Array<DataGridColumn<CprRow>> => {
	const columns: Array<DataGridColumn<CprRow>> = [
		CprRowIndexDataGridColumn({
			accessorFn: (row) => (row as CprRow).getCprIdx(),
			header: 'Index',
			onAddCprNode,
			displayMode
		})
	];

	const cols = cprCols.map((cprCol) =>
		CprColDataGridColumn<CprRow>({
			displayMode,
			id: cprCol.cprNodeTypeId,
			header: CprNodeTypeNameMap.get(cprCol.cprNodeTypeId) ?? '',
			accessorFn: (row) => getCprCol(row as CprRow, cprCol.cprNodeTypeId),
			cprCol
		})
	);

	return columns.concat(cols);
};

export const getCprLabelsColumns = (
	cpr: Cpr,
	displayMode: boolean,
	updateLabels: () => void
): Array<DataGridColumn<CprLbl>> => [
	CprLabelRowIndexDataGridColumn({
		accessorFn: (row) => (row as CprLbl).getCprIdx(),
		cpr,
		header: 'Index',
		displayMode,
		updateLabels
	}),
	CprLabelNameDataGridColumn({
		displayMode,
		onChange: (newName, label) => {
			//Labels can only be 8 characters long
			newName = newName.substr(0, 8);

			label.setName(newName);
			updateLabels();
		},
		accessorFn: (label) => (label as CprLbl).getName(),
		header: 'Label'
	}),
	CprNodeTypeDataGridColumn({
		displayMode,
		accessorFn: (label) => label.cprNodeTypeId,
		header: 'Type',
		onUpdate: (label, nodeType) => {
			label.updateNodeTypeId(nodeType);
			updateLabels();
		}
	}),
	CprLabelValuesDataGridColumn({
		displayMode,
		header: 'Values',
		accessorFn: (label) => (label as CprLbl).getRawValues(),
		onUpdate: () => {
			updateLabels();
		}
	})
];

export const getCprScpsColumns = <T extends CprScpsIndexRequest>(): Array<
	DataGridColumn<CprScpsIndexResponse<T>>
> => [
	DefaultDataGridColumn({
		accessorKey: 'serviceControlPointId',
		header: 'SCP'
	}),
	SomosScpStatusDataGridColumn({
		accessorKey: 'scpStatusId',
		header: 'SCP Status'
	}),
	BooleanDataGridColumn({
		accessorKey: 'inAos',
		header: 'In Area of Service'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Effective Timestamp'
	})
];

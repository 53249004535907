import { NumberGroupIndexRequest, RespOrgIndexResponse } from 'RtModels';
import { NumberGroupsGrid } from 'RtUi/app/rt800/NumberGroups/lib/grids/NumberGroupsGrid';
import { RespOrgSelect } from 'RtUi/app/rt800/RespOrgs/lib/controls/RespOrgSelect';
import { IsActiveRadioFormControl } from 'RtUi/components/form/IsActiveRadioFormControl';
import { RtUiSearchForm } from 'RtUi/components/ui/RtUiForm';
import {
	TabbedLayout,
	TabbedLayoutHelpContainer,
	TabbedLayoutTab
} from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';
import { useReplaceWindowUrl } from 'RtUi/utils/hooks/useReplaceWindowUrl';
import { FC, useState } from 'react';
import { NumberGroupRouter } from './NumberGroup.router';
import { RtxUiPageSizes } from 'RtUi/RtxUiConstants';

export const NumberGroupIndexContainer: FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [tabs, activeTab, setActiveTab] = useTabs({
		Search: { header: 'Search' },
		Results: { header: 'Results', isDefault: true }
	});

	const [isActive, setIsActive] = useState<number | null>(1);
	const [respOrg, setRespOrg] = useState<RespOrgIndexResponse>();

	const resourceParams: NumberGroupIndexRequest = {
		pageSize: RtxUiPageSizes.SoftAll
	};

	if (respOrg) {
		resourceParams.respOrgId = respOrg.respOrgId;
	}

	if (typeof isActive === 'number' && isActive >= 0) {
		resourceParams.isActive = isActive;
	}

	useReplaceWindowUrl(activeTab.header, resourceParams);

	return (
		<TabbedLayout
			router={NumberGroupRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search}>
				<RtUiSearchForm
					onSubmit={(evt) => {
						evt.preventDefault();

						setActiveTab(tabs.Results.header);
					}}
				>
					<RespOrgSelect
						onChange={(rOrg) => {
							setRespOrg(rOrg);
						}}
						value={respOrg}
					/>
					<IsActiveRadioFormControl
						label="Status"
						onChange={(active) => {
							setIsActive(active);
						}}
						value={isActive}
					/>
				</RtUiSearchForm>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<NumberGroupsGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>

			<TabbedLayoutHelpContainer tab={tabs.Results.header}>
				<dl>
					<dt>Profile</dt>
					<dd className="text-muted" style={{ marginBottom: '1em' }}>
						Based on permissions you may have the ability to modify summary
						description RespOrg, and status.
					</dd>
					<dt>Numbers</dt>
					<dd className="text-muted" style={{ marginBottom: '1em' }}>
						Your defined list of numbers to perform tasks against.
					</dd>
					<dt>Audit</dt>
					<dd className="text-muted" style={{ marginBottom: '1em' }}>
						Create a snapshot of number statuses for future comparison use.
					</dd>
				</dl>
			</TabbedLayoutHelpContainer>
		</TabbedLayout>
	);
};

NumberGroupRouter.setIndexRtUiFunctionalComponent(NumberGroupIndexContainer, {
	description: 'Customer defined group of numbers for bulk action.'
});

import * as React from 'react';
import { CdrSearchIndexResponse, Reports } from 'RtModels';
import { RtVueHttp } from 'RtUi/app/rtVue/common/lib/http/RtVueHttp';
import {
	IRtVueContainerState,
	RtVueReportContainer
} from 'RtUi/app/rtVue/common/lib/containers/RtVueReportContainer';
import { ArrayResource } from 'RtUi/utils/http/resources/ArrayResource';
import { HttpResource } from 'RtUi/utils/http/resources/HttpResource';
import { CdrSearchViewRouter } from 'RtUi/app/rtVue/CdrSearchView/CdrSearchView.router';
import { CdrSearchDataGrid } from 'RtUi/app/rtVue/CdrSearchView/lib/grids/CdrSearchDataGrid';
import { RtxUiPageSizes } from 'RtUi/RtxUiConstants';

interface ICdrSearchState extends IRtVueContainerState<CdrSearchIndexResponse> {
	pageSize?: number;
}

@CdrSearchViewRouter.getIndexRtUiController({
	groupName: 'Network Reports',
	groupId: 'VueNetworkReports',
	orderPriority: 3,
	groupOrderPriority: 10
})
export class CdrSearchViewIndexContainer extends RtVueReportContainer<
	CdrSearchIndexResponse,
	ICdrSearchState
> {
	public state: ICdrSearchState = {
		...this.getBaseState(),
		pageSize: RtxUiPageSizes.Default
	};
	protected reportId = Reports.Cdrs;
	protected router = CdrSearchViewRouter;

	public async onChangeRecordsToLoad(numberOfRecords: number) {
		this.setState(
			{
				pageSize: numberOfRecords,
				loadingReport: true
			},
			async () => {
				const resource = await this.getResource();
				this.setState({ resource, loadingReport: false });
			}
		);
	}

	public async getResource(
		allRows: boolean = false
	): Promise<HttpResource<CdrSearchIndexResponse>> {
		const {
			timeRange,
			partition,
			preset,
			filters,
			dataSums,
			additionalColumns
		} = this.state;
		const vueHttp = new RtVueHttp();
		const pageSize = allRows ? RtxUiPageSizes.CdrAll : this.state.pageSize;
		const cdrIndexes = await vueHttp.getCdrSearchView({
			timeRange,
			filters,
			preset,
			dataSums,
			partition,
			dataColumns: additionalColumns?.map((column) => column.value),
			pageSize
		});

		const cdrResource = new ArrayResource<CdrSearchIndexResponse>(
			'vuid',
			cdrIndexes.data
		);

		cdrResource.setTotalCount(cdrIndexes.totalCount);

		return cdrResource;
	}

	protected renderReports(): React.ReactNode {
		return (
			<>
				<CdrSearchDataGrid
					defaultSorting={this.state.defaultSorting}
					isLoading={this.state.loadingReport}
					onSortingChange={this.onSortedChange}
					resource={this.state.resource}
					recordsToLoad={this.state.pageSize}
					container={this}
					onChangeRecordsToLoad={(recordsNumber) => {
						if (recordsNumber) {
							this.onChangeRecordsToLoad(recordsNumber);
						}
					}}
				/>
			</>
		);
	}
}

import { endOfDay } from 'date-fns';
import { useState } from 'react';
import {
	FullSummaryColumns,
	FullSummaryIndexRequest,
	FullSummaryTimeDisplay
} from 'RtModels';
import { FixedRatingSummaryRouter } from 'RtUi/app/rtSip/FixedRatingSummary/FixedRatingSummary.router';
import { FixedRatingSummaryDataGrid } from 'RtUi/app/rtSip/FixedRatingSummary/lib/grids/FixedRatingSummaryDataGrid';
import { SummaryReportSearchForm } from 'RtUi/app/rtSip/FullSummaryReport/lib/components/SummaryReportSearchForm';
import { TabbedLayout, TabbedLayoutTab } from 'RtUi/components/ui/TabbedLayout';
import { useTabs } from 'RtUi/components/ui/TabbedLayout/useTabs';

export const FixedRatingSummaryIndexContainer = () => {
	const [tabs, activeTab, setActiveTab] = useTabs(
		FixedRatingSummaryRouter.getProfileTabs()
	);
	const utcOffset = new Date().getTimezoneOffset();
	const useStartTs = new Date();
	useStartTs.setMinutes(0, 0, 0);
	useStartTs.setMinutes(useStartTs.getMinutes() + utcOffset);
	useStartTs.setHours(useStartTs.getHours() - 3);

	const [resourceParams, setResourceParams] = useState<FullSummaryIndexRequest>(
		{
			startTs: useStartTs,
			endTs: endOfDay(new Date()),
			timeslot: FullSummaryTimeDisplay.None,
			columns: [FullSummaryColumns.EgressRevenueTotal]
		}
	);

	const submitHandler = ({
		startTs,
		endTs,
		...data
	}: FullSummaryIndexRequest) => {
		setResourceParams({
			startTs: new Date(startTs),
			endTs: new Date(endTs),
			...data
		});
		setActiveTab(tabs.Results.header);
	};

	return (
		<TabbedLayout
			router={FixedRatingSummaryRouter}
			activeTab={activeTab.header}
			onTabChange={setActiveTab}
		>
			<TabbedLayoutTab {...tabs.Search} alwaysRender>
				<SummaryReportSearchForm
					resourceParams={resourceParams}
					onSubmit={submitHandler}
				/>
			</TabbedLayoutTab>
			<TabbedLayoutTab {...tabs.Results}>
				<FixedRatingSummaryDataGrid resourceParams={resourceParams} />
			</TabbedLayoutTab>
		</TabbedLayout>
	);
};

FixedRatingSummaryRouter.setIndexRtUiFunctionalComponent(
	FixedRatingSummaryIndexContainer,
	{
		groupName: 'Network Reports',
		orderPriority: 30,
		groupId: 'SipNetworkReports',
		groupOrderPriority: 1030
	}
);

import { TemplateIndexResponse } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import { BooleanDataGridColumn } from 'RtUi/components/data/DataGrid/columns/BooleanDataGridColumn';
import { DefaultDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { DelimiterDataGridColumn } from 'RtUi/components/data/DataGrid/columns/DelimiterDataGridColumn';
import { IntegerDataGridColumn } from 'RtUi/components/data/DataGrid/columns/IntegerDataGridColumn';
import { RespOrgColumnsBuilder } from 'RtUi/components/data/DataGrid/columns/RespOrgDataGridColumn';
import { TimeStampDataGridColumn } from 'RtUi/components/data/DataGrid/columns/TimeStampDataGridColumn';
import { LabelDataGridColumn } from 'RtUi/components/data/DataGrid/columns/LabelDataGridColumn';

export const getTemplateColumns = (): Array<
	DataGridColumn<TemplateIndexResponse>
> => [
	DefaultDataGridColumn({
		accessorKey: 'templateName',
		header: 'Name'
	}),
	LabelDataGridColumn({
		accessorKey: 'label'
	}),
	TimeStampDataGridColumn({
		accessorKey: 'effectiveTs',
		header: 'Effective'
	}),
	...RespOrgColumnsBuilder<TemplateIndexResponse>({
		accessorKey: 'respOrgId',
		header: 'RespOrg'
	}),
	DelimiterDataGridColumn({
		accessorKey: 'interLataCarriers',
		header: 'Carriers',
		delimiter: ','
	}),
	DelimiterDataGridColumn({
		accessorKey: 'areasOfService',
		header: 'AoS',
		delimiter: ','
	}),
	IntegerDataGridColumn({
		accessorKey: 'tfnQty',
		header: 'TFN Qty',
		showFooter: true,
		minSize: 140,
		headerTooltip: 'May be delayed up to an hour'
	}),
	BooleanDataGridColumn({
		accessorKey: 'isActive',
		header: 'Active',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isReserveTemplate',
		header: 'Reserve',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isRoutingTemplate',
		header: 'Routing',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isSourceTemplate',
		header: 'Source',
		isHidden: true
	}),
	BooleanDataGridColumn({
		accessorKey: 'isTargetTemplate',
		header: 'Target',
		isHidden: true
	})
];

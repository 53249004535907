import { RoutingGroupsGrid } from 'RtUi/app/rt800/RoutingGroups/lib/grids/RoutingGroupsGrid';
import { RoutingGroupResource } from 'RtUi/app/rt800/RoutingGroups/lib/resources/RoutingGroupResource';
import { Rt800ApiRoutes } from 'RtExports/routes';
import {
	UiNotification,
	UiNotificationCategoryEnum
} from 'RtUi/notifications/lib/UiNotification';
import { DashboardContent } from 'RtUi/app/rtCommon/DashboardOld/lib/components/DashboardContent';
import { MessageEventType } from 'RtModels';

export class RoutingGroupAuditUiNotification extends UiNotification {
	protected routingGroupResource = new RoutingGroupResource();

	constructor() {
		super(
			UiNotificationCategoryEnum.Rt800,
			Rt800ApiRoutes.RoutingGroups.Index.permissions,
			'Routing Groups Audit',
			MessageEventType.RoutingGroupsAudit
		);

		this.routingGroupResource.setGetAllDoNotNotifyOn404Error(true);
		this.routingGroupResource.setGetAllParams({ isAbnormal: 1 });
	}

	public getIcon() {
		return <i className="fas fa-layer-group" />;
	}

	protected getDetailedView() {
		return (
			<DashboardContent
				notification={this}
				renderWhenHasErrors={() => (
					<RoutingGroupsGrid resourceParams={{ isAbnormal: 1 }} />
				)}
			/>
		);
	}

	protected getIssueCount(): Promise<number> {
		return this.routingGroupResource
			.getAll()
			.then((routingGroupAlternateAudit) => routingGroupAlternateAudit.length);
	}
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $CdrSearchIndexRequest = {
    properties: {
        callDate: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        startTime: {
            type: 'string',
            isRequired: true,
        },
        endTime: {
            type: 'string',
            isRequired: true,
        },
        callingPartyStd: {
            type: 'string',
        },
        callingPartyDigits: {
            type: 'string',
        },
        callingPartyCountryIsoIds: {
            type: 'Array',
        },
        callingPartyRateCenterIds: {
            type: 'Array',
        },
        revenueAccountIds: {
            type: 'Array',
        },
        revenueSubscriptionIds: {
            type: 'Array',
        },
        costAccountIds: {
            type: 'Array',
        },
        costSubscriptionIds: {
            type: 'Array',
        },
        columns: {
            type: 'Array',
        },
        inMin: {
            type: 'number',
        },
        inMax: {
            type: 'number',
        },
        cdrId: {
            type: 'string',
        },
        resultId: {
            type: 'number',
        },
        destinationStd: {
            type: 'string',
        },
        destinationDigits: {
            type: 'string',
        },
        destinationCountryIsoIds: {
            type: 'Array',
        },
        destinationRateCenterIds: {
            type: 'Array',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RouteDepthIndexRequest = {
    properties: {
        iso3166Alpha3s: {
            type: 'Array',
        },
        rateCenterIds: {
            type: 'Array',
        },
        rateKey: {
            type: 'string',
        },
        serviceQualityIds: {
            type: 'Array',
        },
        allInternational: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
        customerTestingOverride: {
            type: 'number',
        },
    },
};
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RoutingGroupTemplateProfileResponse = {
    properties: {
        routingGroupTemplateId: {
            type: 'number',
            isRequired: true,
        },
        routingGroupId: {
            type: 'number',
            isRequired: true,
        },
        templateName: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
        routingTemplateTypeId: {
            type: 'RoutingTemplateTypes',
            isRequired: true,
        },
        isCurrent: {
            type: 'number',
            isRequired: true,
        },
        isActive: {
            type: 'number',
            isRequired: true,
        },
        isValidCpr: {
            type: 'number',
            isRequired: true,
        },
        hasErrors: {
            type: 'number',
            isRequired: true,
        },
        isLocked: {
            type: 'number',
            isRequired: true,
        },
        createdAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
        updatedAt: {
            type: 'Date',
            isRequired: true,
            format: 'date-time',
        },
    },
};
import {
	$SubscriptionProfileResponse,
	AccountIndexResponse,
	Attestation,
	CallflowService,
	InvoiceCycleGroup,
	JurisPrecedenceOptions,
	RatePlanIndexResponse,
	ReleaseCodeIndexResponse,
	RoutePlanIndexResponse,
	ScenarioIndexResponse,
	ServiceIndexResponse,
	SubscriptionCreateRequest,
	SubscriptionIndexResponse,
	SubscriptionProfileResponse,
	SubscriptionUpdateRequest,
	TimezoneIndexResponse,
	TrackingGroupIndexResponse
} from 'RtModels';
import { SubscriptionResource } from 'RtUi/app/AccountManagement/Subscriptions/lib/resources/SubscriptionsResource';
import { Confirmation } from 'RtUi/components/form/Confirmation';
import { RtxForm } from 'RtUi/components/rtx/form';
import { RtxBooleanInput } from 'RtUi/components/rtx/inputs/Boolean/RtxBooleanInput';
import { RtxDateInput } from 'RtUi/components/rtx/inputs/Date/RtxDateInput';
import { RtxIsActiveInput } from 'RtUi/components/rtx/inputs/IsActive/RtxIsActiveInput';
import { RtxNumberInput } from 'RtUi/components/rtx/inputs/Number/RtxNumber';
import { RtxPhoneNumberInput } from 'RtUi/components/rtx/inputs/PhoneNumber/RtxPhoneNumberInput';
import { RtxPrecisionInput } from 'RtUi/components/rtx/inputs/Precision/RtxPrecisionInput';
import { RtxRadioInput } from 'RtUi/components/rtx/inputs/Radio/RtxRadioInput';
import { IDefaultSelectOption } from 'RtUi/components/rtx/inputs/Select/RtxSelectInput';
import { RtxAccountSelect } from 'RtUi/components/rtx/inputs/Select/instances/Account/RtxAccountSelect';
import { RtxAttestationSelect } from 'RtUi/components/rtx/inputs/Select/instances/Attestation/RtxAttestationSelect';
import { RtxInvoiceCycleGroupSelect } from 'RtUi/components/rtx/inputs/Select/instances/InvoiceCycleGroup/RtxInvoiceCycleGroupSelect';
import { RtxRatePlanSelect } from 'RtUi/components/rtx/inputs/Select/instances/RatePlan/RtxRatePlanSelect';
import { RtxRatingPrecedenceSelect } from 'RtUi/components/rtx/inputs/Select/instances/RatingPrecedence/RtxRatingPrecedence';
import { RtxReleaseCodeSelect } from 'RtUi/components/rtx/inputs/Select/instances/ReleaseCode/RtxReleaseCodeSelect';
import { RtxRoutePlanSelect } from 'RtUi/components/rtx/inputs/Select/instances/RoutePlan/RtxRoutePlanSelect';
import { RtxScenarioSelect } from 'RtUi/components/rtx/inputs/Select/instances/Scenario/RtxScenarioSelect';
import { RtxServiceSelect } from 'RtUi/components/rtx/inputs/Select/instances/Service/RtxServiceSelect';
import { RtxCustomerOriginationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxCustomerOriginationSubscriptionSelect';
import { RtxVendorTerminationSubscriptionSelect } from 'RtUi/components/rtx/inputs/Select/instances/Subscription/RtxVendorTerminationSubscriptionSelect';
import { RtxTimezoneSelect } from 'RtUi/components/rtx/inputs/Select/instances/Timezone/RtxTimezoneSelect';
import { RtxTrackingGroupSelect } from 'RtUi/components/rtx/inputs/Select/instances/TrackingGroup/RtxTrackingGroupSelect';
import { RtxTextInput } from 'RtUi/components/rtx/inputs/Text/RtxTextInput';
import { RtxTextAreaInput } from 'RtUi/components/rtx/inputs/TextArea/RtxTextAreaInput';
import { RtError } from 'RtUi/utils/errors/RtError';
import { format } from 'date-fns';
import { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

export enum SectionHeaders {
	profile = 'Profile',
	billing = 'Billing',
	origination = 'Origination (DID / Toll-Free)',
	directVendorTermination = 'Direct Vendor Termination'
}

enum DeactivatedServices {
	All,
	Connections,
	Rates,
	None
}

interface ISubscriptionEditorProps {
	onUpdate: (newSubscription: SubscriptionProfileResponse) => void;
	disablePadding?: boolean;
	editMode?: SubscriptionProfileResponse;
	onCancel?: () => void;
	createMode?: boolean;
	accountId?: number;
}

const getInitialSubscription = (
	accountId?: number
): SubscriptionProfileResponse => ({
	label: '',
	isActive: 1,
	note: null,
	billDays: 7,
	dueDays: 3,
	disputeDays: 30,
	disputeResponseDays: 30,
	rateKeyDeleteDays: 7,
	rateKeyMatchIncreaseDays: 7,
	rateKeyExpandIncreaseDays: 7,
	accountId: accountId ? accountId : -1,
	serviceId: -1,
	// Weekly
	invoiceCycleGroupId: 3,
	trackingGroupId: null,
	// UTC
	timezoneId: 436,
	subscriptionId: 0,
	startDate: new Date(),
	translatedNumberStd: null,
	defaultInterstateRate: 0,
	defaultIntrastateRate: 0,
	defaultIndeterminateRate: 0,
	defaultIncrementSeconds: 6,
	defaultMinimumSeconds: 6,
	defaultRoundingPrecision: 8,
	sipConnectionsCount: 0,
	ratePlansCount: 0
});

export const SubscriptionEditor = ({
	createMode,
	editMode,
	accountId,
	onUpdate
}: ISubscriptionEditorProps) => {
	const [account, setAccount] = useState<AccountIndexResponse>();
	const [serviceType, setServiceType] = useState<ServiceIndexResponse>();
	const [ratePlan, setRatePlan] = useState<RatePlanIndexResponse>();
	const [routePlan, setRoutePlan] = useState<RoutePlanIndexResponse>();
	const [timezone, setTimezone] = useState<TimezoneIndexResponse>();
	const [invoiceCycleGroup, setInvoiceCycleGroup] =
		useState<InvoiceCycleGroup>();
	const [ratingParametersPriority, setRatingParametersPriority] =
		useState<Array<IDefaultSelectOption<JurisPrecedenceOptions>>>();
	const [attestation, setAttestation] =
		useState<IDefaultSelectOption<Attestation>>();
	const [defaultSubscription, setDefaultSubscription] =
		useState<SubscriptionIndexResponse>();
	const [sourceSubscription, setSourceSubscription] =
		useState<SubscriptionIndexResponse>();
	const [trackingGroup, setTrackingGroup] =
		useState<TrackingGroupIndexResponse>();
	const [defaultIsdnRelease, setDefaultIsdnRelease] =
		useState<ReleaseCodeIndexResponse>();
	const [defaultScenario, setDefaultScenario] =
		useState<ScenarioIndexResponse>();
	const [vendorSubscription, setVendorSubscription] =
		useState<SubscriptionIndexResponse>();
	const [displayMode, setDisplayMode] = useState<boolean>(Boolean(editMode));
	const [deactivatedServices, setDeactivatedServices] =
		useState<DeactivatedServices>();
	const [isNewRatePlan, setIsNewRatePlan] = useState<boolean>();
	const [error, setError] = useState<RtError>();

	const getDeactivateConfirmMessage = (
		sipConnectionsCount: number,
		ratePlansCount: number
	): string => {
		const sipConnectionsMessage = `${sipConnectionsCount} SIP Connections`;
		const ratePlansMessage =
			ratePlansCount === 1
				? `${ratePlansCount} Rates`
				: 'Rates will NOT be deactivated because this is a shared rate plan';

		if (sipConnectionsCount && !ratePlansCount) {
			return `${sipConnectionsMessage} will be deactivated!`;
		}

		if (!sipConnectionsCount && ratePlansCount) {
			return ratePlansCount === 1
				? `${ratePlansMessage} will be deactivated`
				: ratePlansMessage;
		}

		return ratePlansCount === 1
			? `${sipConnectionsMessage} and ${ratePlansMessage} will be deactivated!`
			: `${sipConnectionsMessage} will be turned off. ${ratePlansMessage}`;
	};

	const onSubmit = async (data: SubscriptionProfileResponse) => {
		const deactivating =
			!data.isActive &&
			![undefined, DeactivatedServices.None].includes(deactivatedServices);

		if (deactivating && deactivatedServices === DeactivatedServices.All) {
			const deactivatedMessage = getDeactivateConfirmMessage(
				data.sipConnectionsCount,
				data.ratePlansCount
			);

			const confirm = await Confirmation.create(
				<>
					<p>You are trying to deactivate all the services in this account.</p>
					<b className="d-block mb-3">{deactivatedMessage}</b>
					<p>Do you want to continue?</p>
				</>
			);

			if (!confirm) {
				return;
			}
		}

		try {
			let newSubscription: SubscriptionProfileResponse;
			setError(undefined);
			const subscriptionResource = new SubscriptionResource();

			if (createMode) {
				newSubscription = await subscriptionResource.create(
					data as SubscriptionCreateRequest
				);
				onUpdate(newSubscription);
			} else {
				const updateParams: SubscriptionUpdateRequest = {
					label: data.label,
					serviceId: data.serviceId,
					ratePlanId: data.ratePlanId,
					note: data.note ?? undefined,
					externalAccountId: data.externalAccountId ?? undefined,
					externalSubAccountId: data.externalSubAccountId ?? undefined,
					deactivate: {
						ackConnections: Number(
							deactivating &&
								[
									DeactivatedServices.Connections,
									DeactivatedServices.All
								].includes(deactivatedServices as DeactivatedServices)
						),
						ackRates: Number(
							deactivating &&
								[DeactivatedServices.Rates, DeactivatedServices.All].includes(
									deactivatedServices as DeactivatedServices
								)
						)
					},
					isActive: data.isActive,
					blockTypeId: data.blockTypeId ?? undefined,
					attestation: data.attestation ?? null,
					startDate: data.startDate
				};

				newSubscription = await subscriptionResource.update(
					data.subscriptionId,
					updateParams
				);
			}
			setDisplayMode(true);
			onUpdate(newSubscription);
		} catch (error: any) {
			setError(new RtError(error));
		}
	};

	return (
		<Row>
			<Col lg={createMode ? 12 : 8}>
				<RtxForm<SubscriptionProfileResponse>
					defaultValues={editMode || getInitialSubscription(accountId)}
					onSubmit={onSubmit}
					error={
						error && {
							name: 'root',
							error: {
								message: error.message
							}
						}
					}
					createMode={createMode}
					displayMode={displayMode}
					onEdit={() => setDisplayMode(false)}
					onCancel={() => setDisplayMode(true)}
				>
					{({ control, watch, setValue }) => {
						const [isActive, sipConnectionsCount, ratePlansCount] = watch([
							'isActive',
							'sipConnectionsCount',
							'ratePlansCount'
						]);

						const displayDeactivateServicesRadioButtons =
							!displayMode &&
							!isActive &&
							(sipConnectionsCount !== 0 || ratePlansCount !== 0);

						return (
							<Row col={2}>
								<Col>
									<fieldset className="card card-body mb-0">
										<legend className="card-header">
											<h6>{SectionHeaders.profile}</h6>
										</legend>
										<Controller
											control={control}
											name="accountId"
											render={({ field: { onChange, value } }) => (
												<RtxAccountSelect
													label="Account"
													className="mb-3"
													value={account}
													onChange={(account) => {
														setAccount(account);
														onChange(account.accountId);
													}}
													displayMode={!Boolean(createMode)}
													initialOptionId={value ?? undefined}
													required={
														$SubscriptionProfileResponse.properties.accountId
															.isRequired
													}
												/>
											)}
										/>
										<Controller
											control={control}
											name="label"
											render={({ field: { onChange, value = '' } }) => (
												<RtxTextInput
													className="mb-3"
													label="Label"
													displayMode={displayMode}
													value={value}
													onChange={(value) => onChange(value ?? '')}
													required={
														$SubscriptionProfileResponse.properties.label
															.isRequired
													}
												/>
											)}
										/>
										{!Boolean(createMode) && (
											<>
												<Controller
													control={control}
													name="isActive"
													render={({ field: { onChange, value } }) => (
														<RtxIsActiveInput
															displayMode={displayMode}
															value={value}
															hideBothOption
															className="mb-3"
															onChange={onChange}
															required={
																$SubscriptionProfileResponse.properties.isActive
																	.isRequired
															}
														/>
													)}
												/>
												{displayDeactivateServicesRadioButtons && (
													<RtxRadioInput<DeactivatedServices>
														label="Deactivated Services"
														vertical
														color="error"
														value={deactivatedServices}
														onChange={(value) =>
															setDeactivatedServices(Number(value))
														}
														required
														options={[
															{
																value: DeactivatedServices.None,
																label: 'None',
																tooltip:
																	'The subscription will be deactivated but the related Connections and Rates will continue to be active'
															},
															{
																value: DeactivatedServices.All,
																label: 'All',
																tooltip:
																	'The subscription will be deactivated along with the related Connections and Rates'
															},
															{
																value: DeactivatedServices.Connections,
																label: 'Connections',
																tooltip:
																	'The subscription will be deactivated with the related Connections but the Rates will continue to be active'
															},
															{
																value: DeactivatedServices.Rates,
																label: 'Rates',
																tooltip:
																	'the subscription will be deactivated with the related Rates but the Connections will continue to be active'
															}
														]}
													/>
												)}
											</>
										)}
										<Controller
											control={control}
											name="serviceId"
											render={({ field: { onChange, value } }) => (
												<RtxServiceSelect
													className="mb-3"
													label="Service Type"
													displayMode={displayMode || !Boolean(createMode)}
													onChange={(serviceType) => {
														setServiceType(serviceType);
														onChange(serviceType.serviceId);

														if (
															serviceType.serviceId !==
															CallflowService.InboundVendorOrigination
														) {
															setDefaultSubscription(undefined);
															setValue('defaultSubscriptionId', undefined);

															setValue('defaultIsdnReleaseId', undefined);
															setDefaultIsdnRelease(undefined);

															setValue('defaultIsdnReleaseMs', undefined);
															setValue('hasServiceNumbers', undefined);
														}

														if (
															serviceType.serviceId !==
															CallflowService.InboundCustomerIdentificationClone
														) {
															setValue('sourceSubscriptionId', undefined);
															setSourceSubscription(undefined);
														}

														if (
															serviceType.serviceId !==
															CallflowService.OutboundCustomerIdentification
														) {
															setValue('trackingGroupId', undefined);
															setTrackingGroup(undefined);
															setValue('translatedNumberStd', null);
														}

														if (
															![
																CallflowService.OutboundCustomerTermination,
																CallflowService.OutboundCustomerIdentification
															].includes(serviceType.serviceId)
														) {
															setValue('directSubscriptionId', undefined);
															setVendorSubscription(undefined);
														}

														if (
															![
																CallflowService.InboundCustomerIdentification,
																CallflowService.InboundCustomerIdentificationClone,
																CallflowService.OutboundCustomerIdentification
															].includes(serviceType.serviceId)
														) {
															setValue('defaultScenarioId', undefined);
															setDefaultScenario(undefined);
														}
													}}
													value={serviceType}
													initialOptionId={value}
													required={Boolean(createMode)}
												/>
											)}
										/>
										{Boolean(createMode) && (
											<RtxRadioInput<boolean>
												label="Rate Plan"
												onChange={(value) => setIsNewRatePlan(value === 'true')}
												value={isNewRatePlan}
												required
												className="mb-3"
												options={[
													{ value: true, label: 'Create a New Rate Plan' },
													{ value: false, label: 'Use a Shared Rate Plan' }
												]}
											/>
										)}
										{(isNewRatePlan === false || Boolean(editMode)) && (
											<Controller
												control={control}
												name="ratePlanId"
												render={({ field: { onChange, value } }) => (
													<RtxRatePlanSelect
														className="mb-3"
														required={isNewRatePlan === false}
														label={`${Boolean(createMode) ? '' : 'Rate Plan'}`}
														displayMode={displayMode}
														onChange={(ratePlan: RatePlanIndexResponse) => {
															setRatePlan(ratePlan);
															onChange(ratePlan.ratePlanId);
														}}
														value={ratePlan}
														isShared={isNewRatePlan === false || undefined}
														assignableToSubscriptionId={
															editMode?.subscriptionId
														}
														initialOptionId={value ?? undefined}
													/>
												)}
											/>
										)}
										<Controller
											control={control}
											name="note"
											render={({ field: { onChange, value } }) => (
												<RtxTextAreaInput
													label="Notes"
													className="mb-3"
													displayMode={displayMode}
													value={value ?? ''}
													onChange={onChange}
												/>
											)}
										/>
										{!Boolean(createMode) && (
											<Controller
												control={control}
												name="lastRateLoadTs"
												render={({ field: { value } }) => (
													<RtxTextInput
														label="Last Rate Load"
														className="mb-3"
														displayMode
														value={
															value
																? format(
																		new Date(value),
																		'yyyy-MM-dd hh:mm:ss aa'
																	)
																: undefined
														}
													/>
												)}
											/>
										)}
										{Boolean(createMode) && (
											<Controller
												control={control}
												name="routePlanId"
												render={({ field: { onChange, value } }) => (
													<RtxRoutePlanSelect
														name="routePlanId"
														className="mb-3"
														displayMode={displayMode}
														isClearable={
															!serviceType ||
															![
																CallflowService.OutboundCustomerTermination,
																CallflowService.InboundCustomerIdentification,
																CallflowService.InboundCustomerIdentificationClone,
																CallflowService.OutboundCustomerIdentification
															].includes(serviceType.serviceId)
														}
														onChange={(
															routePlan: RoutePlanIndexResponse | undefined
														) => {
															setRoutePlan(routePlan ? routePlan : undefined);
															onChange(routePlan?.routePlanId);
														}}
														required={
															serviceType &&
															[
																CallflowService.OutboundCustomerTermination,
																CallflowService.InboundCustomerIdentification,
																CallflowService.InboundCustomerIdentificationClone,
																CallflowService.OutboundCustomerIdentification
															].includes(serviceType.serviceId)
														}
														value={routePlan}
														initialOptionId={value ?? undefined}
														allowedRoutePlanIds={[3, 6]}
													/>
												)}
											/>
										)}
										<Controller
											control={control}
											name="startDate"
											render={({ field: { onChange, value } }) => (
												<RtxDateInput
													label="Start Date"
													className="mb-3"
													displayMode={displayMode}
													required
													value={value ? new Date(value) : undefined}
													onChange={onChange}
												/>
											)}
										/>
										<Controller
											control={control}
											name="externalAccountId"
											render={({ field: { onChange, value } }) => (
												<RtxTextInput
													label="External Account Id"
													className="mb-3"
													displayMode={displayMode}
													value={value ?? ''}
													onChange={(value) => onChange(value ?? '')}
												/>
											)}
										/>
										<Controller
											control={control}
											name="externalSubAccountId"
											render={({ field: { onChange, value } }) => (
												<RtxTextInput
													label="External Sub Account Id"
													className="mb-3"
													displayMode={displayMode}
													value={value ?? ''}
													onChange={(value) => onChange(value ?? '')}
												/>
											)}
										/>
										<Controller
											control={control}
											name="attestation"
											render={({ field: { onChange, value } }) => (
												<RtxAttestationSelect
													displayMode={displayMode}
													onChange={(attestation) => {
														setAttestation(attestation);
														onChange(attestation.value);
													}}
													value={attestation}
													initialOptionId={value ?? undefined}
												/>
											)}
										/>
									</fieldset>
								</Col>
								{createMode && (
									<>
										<Col lg={6}>
											<fieldset className="card card-body mb-0">
												<legend className="card-header">
													<h6>{SectionHeaders.origination}</h6>
												</legend>
												<Controller
													control={control}
													name="defaultSubscriptionId"
													render={({ field: { onChange, value } }) => (
														<RtxCustomerOriginationSubscriptionSelect
															label="Default Subscription"
															className="mb-3"
															isActive={1}
															displayMode={displayMode}
															isDisabled={
																serviceType?.serviceId !==
																CallflowService.InboundVendorOrigination
															}
															onChange={(defaultSubscription) => {
																setDefaultSubscription(defaultSubscription);
																onChange(defaultSubscription.subscriptionId);
															}}
															required={
																serviceType &&
																CallflowService.InboundVendorOrigination ===
																	serviceType.serviceId
															}
															value={defaultSubscription}
															initialOptionId={value ?? undefined}
														/>
													)}
												/>
												<Controller
													control={control}
													name="sourceSubscriptionId"
													render={({ field: { onChange, value } }) => (
														<RtxCustomerOriginationSubscriptionSelect
															label="Clone Subscription"
															isActive={1}
															className="mb-3"
															displayMode={displayMode}
															isDisabled={
																serviceType?.serviceId !==
																CallflowService.InboundCustomerIdentificationClone
															}
															required={
																serviceType?.serviceId ===
																CallflowService.InboundCustomerIdentificationClone
															}
															onChange={(sourceSubscription) => {
																setSourceSubscription(sourceSubscription);
																onChange(sourceSubscription.subscriptionId);
															}}
															value={sourceSubscription}
															initialOptionId={value ?? undefined}
														/>
													)}
												/>
												<Controller
													control={control}
													name="trackingGroupId"
													render={({ field: { onChange, value } }) => (
														<RtxTrackingGroupSelect
															label="Tracking Group"
															className="mb-3"
															displayMode={displayMode}
															isDisabled={
																serviceType?.serviceId !==
																CallflowService.OutboundCustomerIdentification
															}
															onChange={(trackingGroup) => {
																setTrackingGroup(trackingGroup);
																onChange(trackingGroup.trackingGroupId);
															}}
															value={trackingGroup}
															initialOptionId={value ?? undefined}
														/>
													)}
												/>
												<Controller
													control={control}
													name="defaultIsdnReleaseId"
													render={({ field: { onChange, value } }) => (
														<RtxReleaseCodeSelect
															className="mb-3"
															allowedReleaseCodeIds={[1, 17, 18, 28, 34]}
															displayMode={displayMode}
															isDisabled={
																serviceType?.serviceId !==
																CallflowService.InboundVendorOrigination
															}
															label="Default ISDN Rel."
															valueKey="isdnReleaseId"
															value={defaultIsdnRelease}
															initialOptionId={value ?? undefined}
															onChange={(defaultIsdnReleaseId) => {
																setDefaultIsdnRelease(defaultIsdnReleaseId);
																onChange(defaultIsdnReleaseId.isdnReleaseId);
															}}
														/>
													)}
												/>
												<Controller
													control={control}
													name="defaultIsdnReleaseMs"
													render={({ field: { onChange, value } }) => (
														<RtxNumberInput
															min={0}
															max={18000}
															className="mb-3"
															label="Default ISDN Rel. ms"
															displayMode={displayMode}
															disabled={
																CallflowService.InboundVendorOrigination !==
																serviceType?.serviceId
															}
															value={value}
															onChange={(value) => {
																onChange(Number(value));
															}}
														/>
													)}
												/>
												<Controller
													control={control}
													name="hasServiceNumbers"
													render={({ field: { onChange, value } }) => (
														<RtxBooleanInput
															className="mb-3"
															label="Service Number Override"
															displayMode={displayMode}
															disabled={
																serviceType?.serviceId !==
																CallflowService.InboundVendorOrigination
															}
															value={value}
															onChange={(value) => onChange(value)}
														/>
													)}
												/>
												<Controller
													control={control}
													name="defaultScenarioId"
													render={({ field: { onChange, value } }) => (
														<RtxScenarioSelect
															label="Default Scenario"
															displayMode={displayMode}
															className="mb-3"
															isDisabled={
																!(
																	serviceType &&
																	[
																		CallflowService.InboundCustomerIdentification,
																		CallflowService.InboundCustomerIdentificationClone,
																		CallflowService.OutboundCustomerIdentification
																	].includes(serviceType.serviceId)
																)
															}
															required={
																serviceType &&
																[
																	CallflowService.InboundCustomerIdentification,
																	CallflowService.InboundCustomerIdentificationClone
																].includes(serviceType.serviceId)
															}
															isClearable={
																!(
																	serviceType &&
																	[
																		CallflowService.InboundCustomerIdentification,
																		CallflowService.InboundCustomerIdentificationClone
																	].includes(serviceType.serviceId)
																)
															}
															onChange={(defaultScenario) => {
																onChange(defaultScenario?.scenarioId);
																setDefaultScenario(defaultScenario);
															}}
															value={defaultScenario}
															initialOptionId={value ?? undefined}
														/>
													)}
												/>
												<Controller
													control={control}
													name="translatedNumberStd"
													render={({ field: { onChange, value } }) => (
														<RtxPhoneNumberInput
															className="mb-3"
															label="Translated Number"
															displayMode={displayMode}
															disabled={
																CallflowService.OutboundCustomerIdentification !==
																serviceType?.serviceId
															}
															value={value ?? ''}
															onChange={(value) => onChange(value)}
														/>
													)}
												/>
											</fieldset>
											<fieldset className="card card-body mb-0">
												<legend className="card-header">
													<h6>{SectionHeaders.directVendorTermination}</h6>
												</legend>
												<Controller
													control={control}
													name="directSubscriptionId"
													render={({ field: { onChange, value } }) => (
														<RtxVendorTerminationSubscriptionSelect
															label="Direct Routing"
															isActive={1}
															displayMode={displayMode}
															isDisabled={
																!(
																	serviceType &&
																	[
																		CallflowService.OutboundCustomerTermination,
																		CallflowService.OutboundCustomerIdentification
																	].includes(serviceType.serviceId)
																)
															}
															isClearable
															onChange={(vendorSubscription) => {
																setVendorSubscription(vendorSubscription);
																onChange(vendorSubscription?.subscriptionId);
															}}
															value={vendorSubscription}
															initialOptionId={value ?? undefined}
														/>
													)}
												/>
											</fieldset>
										</Col>
										<Col lg={12}>
											<Accordion className="mb-4">
												<Accordion.Item eventKey="0">
													<Accordion.Header>
														<b>{SectionHeaders.billing}</b>
													</Accordion.Header>
													<Accordion.Body>
														<Row>
															<Col lg={6}>
																<Controller
																	control={control}
																	name="invoiceCycleGroupId"
																	render={({ field: { onChange, value } }) => (
																		<RtxInvoiceCycleGroupSelect
																			label="Invoice Cycle Group"
																			className="mb-3"
																			displayMode={displayMode}
																			onChange={(
																				invoiceCycleGroup: InvoiceCycleGroup
																			) => {
																				setInvoiceCycleGroup(invoiceCycleGroup);
																				onChange(
																					invoiceCycleGroup.invoiceCycleGroupId
																				);
																			}}
																			value={invoiceCycleGroup}
																			initialOptionId={value ?? undefined}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="billDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			className="mb-3"
																			required={Boolean(editMode)}
																			label="Bill Days"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="disputeDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			className="mb-3"
																			required={Boolean(editMode)}
																			label="Dispute Days"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="rateKeyDeleteDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			className="mb-3"
																			required={Boolean(editMode)}
																			label="Rate Key Delete Days"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="rateKeyExpandIncreaseDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			className="mb-3"
																			required={Boolean(editMode)}
																			label="Rate Key Expand Increase Days"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="defaultMinimumSeconds"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			className="mb-3"
																			label="Default Minimum Seconds"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="defaultIncrementSeconds"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			className="mb-3"
																			label="Default Increment Seconds"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="defaultRoundingPrecision"
																	render={({
																		field: { onChange, value = 0 }
																	}) => (
																		<RtxNumberInput
																			displayMode={displayMode}
																			className="mb-3"
																			label="Default Rounding Precision"
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
															</Col>
															<Col lg={6}>
																<Controller
																	control={control}
																	name="timezoneId"
																	render={({ field: { onChange, value } }) => (
																		<RtxTimezoneSelect
																			required={Boolean(editMode)}
																			displayMode={displayMode}
																			onChange={(
																				timezone: TimezoneIndexResponse
																			) => {
																				setTimezone(timezone);
																				onChange(timezone.timezoneId);
																			}}
																			className="mb-3"
																			value={timezone}
																			initialOptionId={value ?? undefined}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="dueDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			required={Boolean(editMode)}
																			label="Due Days"
																			className="mb-3"
																			displayMode={displayMode}
																			value={value}
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="disputeResponseDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			required={Boolean(editMode)}
																			label="Dispute Response Days"
																			className="mb-3"
																			displayMode={displayMode}
																			value={value}
																			onChange={(value) =>
																				onChange(Number(value))
																			}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="rateKeyMatchIncreaseDays"
																	render={({ field: { onChange, value } }) => (
																		<RtxNumberInput
																			required={Boolean(editMode)}
																			label="Rate Key Match Increase Days"
																			displayMode={displayMode}
																			className="mb-3"
																			value={value}
																			onChange={(value) =>
																				onChange(Number(value))
																			}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="defaultInterstateRate"
																	render={({ field: { onChange, value } }) => (
																		<RtxPrecisionInput
																			precision={6}
																			label="Default Interstate Rate"
																			displayMode={displayMode}
																			value={value}
																			className="mb-3"
																			onChange={onChange}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="defaultIntrastateRate"
																	render={({ field: { onChange, value } }) => (
																		<RtxPrecisionInput
																			precision={6}
																			label="Default Intrastate Rate"
																			displayMode={displayMode}
																			value={value}
																			className="mb-3"
																			onChange={(value) =>
																				onChange(Number(value))
																			}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="defaultIndeterminateRate"
																	render={({ field: { onChange, value } }) => (
																		<RtxPrecisionInput
																			precision={6}
																			label="Default Indeterminate Rate"
																			displayMode={displayMode}
																			value={value}
																			className="mb-3"
																			onChange={(value) =>
																				onChange(Number(value))
																			}
																		/>
																	)}
																/>
																<Controller
																	control={control}
																	name="ratingParametersPriority"
																	render={({ field: { onChange, value } }) => (
																		<RtxRatingPrecedenceSelect<true>
																			isMulti
																			label="Rating Precedence"
																			displayMode={displayMode}
																			initialOptionId={value ?? undefined}
																			value={ratingParametersPriority}
																			onChange={(ratingPrecedence) => {
																				setRatingParametersPriority(
																					ratingPrecedence
																				);
																				onChange(
																					ratingPrecedence.map((v) => v.value)
																				);
																			}}
																		/>
																	)}
																/>
															</Col>
														</Row>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</Col>
									</>
								)}
							</Row>
						);
					}}
				</RtxForm>
			</Col>
		</Row>
	);
};

import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnAlignment,
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { sumColumnTotal } from 'RtUi/components/data/DataGrid/utils';
import { isNil } from 'lodash-es';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IntegerDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {
	showFooter?: boolean;
	humanize?: moment.DurationInputArg2;
	fallbackValue?: string;
	headerTooltip?: string;
}

export const IntegerDataGridColumn = <T = any,>({
	showFooter = false,
	align = DataGridColumnAlignment.RIGHT,
	humanize,
	fallbackValue,
	headerTooltip,
	...config
}: IntegerDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	let getHeader;
	if (headerTooltip) {
		getHeader = () => (
			<OverlayTrigger
				overlay={(props) => <Tooltip {...props}>{headerTooltip}</Tooltip>}
			>
				{({ ref, ...triggerHandler }) => (
					<span ref={ref} {...triggerHandler}>
						{config.header}&nbsp;&nbsp;
						<i className="fas fa-info-circle text-info" />
					</span>
				)}
			</OverlayTrigger>
		);
	}

	const getValue = (value: any, isExporting?: boolean) => {
		if (isNil(value)) {
			return fallbackValue ? fallbackValue : '';
		}

		if (isExporting) {
			return Number(value);
		}

		if (humanize) {
			return moment.duration(value, humanize).humanize();
		}

		return Number(value).toLocaleString('en-US');
	};

	return DefaultDataGridColumn({
		...config,
		align,
		exportValue: (value: any) => getValue(value, true),
		getValue: ({ cell }) => getValue(cell.getValue()),
		getHeader: getHeader,
		...(showFooter && {
			getFooter: (props) => {
				const total = sumColumnTotal(props, (row) =>
					Number(row.getValue(props.column.id))
				);

				if (total === null) {
					return '';
				}

				return total.toLocaleString('en-US');
			}
		})
	});
};

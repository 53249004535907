import { CprStatus } from 'RtModels';
import { DataGridColumn } from 'RtUi/components/data/DataGrid/types';
import {
	DataGridColumnConfiguration,
	DefaultDataGridColumn
} from 'RtUi/components/data/DataGrid/columns/DefaultDataGridColumn';
import { scpStatusIdToName } from 'RtUi/utils/maps';

interface SomosScpStatusDataGridColumnConfiguration<T>
	extends DataGridColumnConfiguration<T> {}

export const SomosScpStatusDataGridColumn = <T = any,>({
	...config
}: SomosScpStatusDataGridColumnConfiguration<T>): DataGridColumn<T> => {
	const getValue = (value: CprStatus) => {
		return scpStatusIdToName(value) ?? '';
	};

	const renderValue = (value: string) => {
		const theme = value === 'OK' ? 'success' : 'warning';

		return (
			<section className="d-flex">
				<div
					role="alert"
					style={{ textAlign: 'center' }}
					className={`alert-sm w-100 mb-0 p-0 alert alert-${theme}`}
				>
					<span>{value}</span>
				</div>
			</section>
		);
	};

	return DefaultDataGridColumn({
		...config,
		exportValue: (value: CprStatus) => getValue(value),
		getValue: ({ cell }) => renderValue(getValue(cell.getValue<CprStatus>()))
	});
};

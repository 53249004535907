/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $ResourceIndexRequest = {
    properties: {
        isActive: {
            type: 'number',
        },
        isDefault: {
            type: 'number',
        },
        search: {
            type: 'string',
        },
        integrationId: {
            type: 'Integrations',
        },
        includeResourceTypes: {
            type: 'Array',
        },
        excludeResourceTypes: {
            type: 'Array',
        },
        hasTaskTypeId: {
            type: 'IntegrationTaskType',
        },
        isNanp: {
            type: 'number',
        },
        isIddd: {
            type: 'number',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
    },
};
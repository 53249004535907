import { AllRow } from 'Somos/lib/SomosCpr/RtCprV2/AllRow';
import { AosColAreaCode } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColAreaCode';
import { AosColLata } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColLata';
import { AosColNetwork } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColNetwork';
import { AosColState } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosColState';
import { AosLblAreaCode } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLblAreaCode';
import { AosLblLata } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLblLata';
import { AosLblState } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLblState';
import { CprError } from 'Somos/lib/SomosCpr/RtCprV2/CprError';
import { CprLblAreaCode } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblAreaCode';
import { CprLblDate } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblDate';
import { CprLblDayOfWeek } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblDayOfWeek';
import { CprLblLata } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblLata';
import { CprLblNpaNxx } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblNpaNxx';
import { CprLblSixDigit } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblSixDigit';
import { CprLblState } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblState';
import { CprLblTenDigit } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblTenDigit';
import { CprLblTimeOfDay } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLblTimeOfDay';
import { CprLergCache } from 'Somos/lib/SomosCpr/RtCprV2/CprLergCache';
import { CprRow } from 'Somos/lib/SomosCpr/RtCprV2/CprRow';
import { CprTreeNode } from 'Somos/lib/SomosCpr/RtCprV2/CprTreeNode';
import type { AosCol } from 'Somos/lib/SomosCpr/RtCprV2/AosCol/AosCol';
import type { AosLbl } from 'Somos/lib/SomosCpr/RtCprV2/AosLbl/AosLbl';
import type { CprCol } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprCol';
import { type CprColCarrier } from 'Somos/lib/SomosCpr/RtCprV2/CprCol/CprColCarrier';
import {
	AosNetwork,
	AosNodeType,
	CprApprovalIndicator,
	CprErrorType,
	CprLergPoint,
	CprNodeType,
	CprSection,
	CprStatus,
	CprStatusLabels,
	type ICleanConfigRequest,
	type ICprBulkCarrierResponse,
	type ICprCleanResponse,
	type ICprLerg,
	type RoutingCacheTypes,
	CprValueHighlightType
} from 'Somos/lib/SomosCpr/RtCprV2/CprConstants';
import { type CprLbl } from 'Somos/lib/SomosCpr/RtCprV2/CprLbl/CprLbl';
import { type CprTwig } from 'Somos/lib/SomosCpr/RtCprV2/CprTwig';

// exported definitions
// ======================================================================

export interface ICprRoutingSearch {
	tenDigitCpn: string;
	lata: string;
	state: string;
};

export interface ICprRoutingResult {
	routingCacheKey: string;
	routingCacheTypeId: RoutingCacheTypes;
	effectiveTs: Date;
	respOrgId: string;
	carrier: string | null;
	announcement: string | null;
	cprIdx: number | null;
	percent: number | null;
};

export class Cpr {
	public readonly sourceRespOrgId: string;

	public readonly routingCacheTypeId: RoutingCacheTypes;
	public readonly routingCacheKey: string;

	private readonly sourceEffectiveTs: Date | null;
	private readonly sourceRecVersionId: string | null = null;
	private sourceTemplateName: string | null = null;

	private targetRespOrgId: string | null = null;
	private targetEffectiveTs: Date | null = null;
	private targetTemplateName: string | null = null;

	private cprStatusId: CprStatus = CprStatus.Unknown;
	private approvalIndicator: CprApprovalIndicator = CprApprovalIndicator.Unknown;

	private contactName: string = '';
	private contactNumber: string = '';

	private notes: string = '';
	private summary: string = '';

	private company: string | null = null;
	private timeZone: string = 'C';
	private daylightSavings: string = 'Y';

	private lineQty: number = 9999;

	private interLataCarriers: string[] = [];
	private intraLataCarriers: string[] = [];

	private errors: CprError[] = [];

	// CAD and TAD only

	// public for convenience
	// eslint-disable-next-line @typescript-eslint/member-ordering
	public readonly aos: {
		areaCodes: AosColAreaCode;
		latas: AosColLata;
		network: AosColNetwork;
		states: AosColState;
	};

	private readonly aosLabels: AosLbl[] = [];
	private readonly aosLabelsByKey: { [key: string]: AosLbl; } = {};

	private readonly aosCols: AosCol[] = [];
	private readonly aosColsByKey: { [key in AosNodeType]?: AosCol; } = {};

	private aosLergKeys: { [key: string]: boolean; } = {};

	private readonly cprLabels: CprLbl[] = [];
	private readonly cprLabelsByKey: { [key: string]: CprLbl; } = {};

	private readonly cprCols: CprCol[] = [];
	private readonly cprColsByKey: { [key in CprNodeType]?: CprCol; } = {};

	// used to store global/complete list of values
	private readonly allRow: AllRow;

	private readonly cprRows: CprRow[] = [];

	// UI and control specific
	private readonly maxCleanIterations = 10;

	private isValidationEnabled: boolean = true;

	private areCarriersCurrentlyInSync: boolean = false;

	private validateListeners: Array<() => void> = [];

	private highlightNodeTypeId: CprNodeType | undefined;

	private highlightCic: string | undefined;
	private highlightValue: string | undefined;

	private highlightChildren: boolean = false;
	private highlightParents: boolean = false;

	private highlightKeys: { [key: string]: CprValueHighlightType; } = {};

	private highlightedRows: CprRow[] = [];
	private selectedHighlightedRow: CprRow | undefined;

	private highlightedLabels: CprLbl[] = [];
	private selectedHighlightedLabel: CprLbl | undefined;

	public constructor(
		respOrgId: string,
		routingCacheTypeId: RoutingCacheTypes,
		routingCacheKey: string,
		sourceEffectiveTs: Date | null = null,
		sourceRecVersionId: string | null = null
	) {
		this.routingCacheTypeId = routingCacheTypeId;
		this.routingCacheKey = routingCacheKey;

		this.allRow = new AllRow(this, 0);

		this.sourceRespOrgId = respOrgId;
		this.sourceEffectiveTs = (sourceEffectiveTs) ? new Date(sourceEffectiveTs.getTime()) : null;
		this.sourceRecVersionId = sourceRecVersionId;

		this.aos = {
			areaCodes: new AosColAreaCode(this, 0),
			latas: new AosColLata(this, 0),
			network: new AosColNetwork(this, 0),
			states: new AosColState(this, 0),
		};

		//temp fix
		this.makeAosCol(AosNodeType.AreaCode);
		this.makeAosCol(AosNodeType.Lata);
		this.makeAosCol(AosNodeType.Network);
		this.makeAosCol(AosNodeType.State);
	}

	/**
	 * Add a listener that is invoked after a validate occurs
	 * @returns a function to remove the listener when invoked
	 */
	public onValidate(uuidListener: () => void) {
		this.validateListeners.push(uuidListener);

		return () => {
			const indexOfListener = this.validateListeners.indexOf(uuidListener);

			this.validateListeners.splice(indexOfListener, 1);
		};
	}

	public doValidation(): boolean {
		return this.isValidationEnabled;
	}

	public setValidation(isValidationEnabled: boolean = true) {
		this.isValidationEnabled = isValidationEnabled;
		if (this.isValidationEnabled) {
			this.validate();
		}
	}

	public getSourceReferenceKey() {
		return this.routingCacheKey;
	}

	public getRoutingProfileType() {
		return this.routingCacheTypeId;
	}

	public getSourceRecVersionId(): string | null {
		return this.sourceRecVersionId;
	}

	public getSourceRespOrgId(): string {
		return this.sourceRespOrgId;
	}

	public getSourceTemplateName(): string | null {
		return this.sourceTemplateName;
	}

	public setSourceTemplateName(templateName: string | null) {
		this.sourceTemplateName = templateName;
	}

	public getTargetRespOrgId(): string | null {
		return this.targetRespOrgId;
	}

	public setTargetRespOrgId(respOrgId: string | null) {
		this.targetRespOrgId = respOrgId;
	}

	public getTargetTemplateName(): string | null {
		return this.targetTemplateName;
	}

	public setTargetTemplateName(templateName: string | null) {
		this.targetTemplateName = templateName;
	}

	public getSummary() {
		return this.summary;
	}

	public setSummary(summary: string) {
		this.summary = summary;
	}

	public getNotes() {
		return this.notes;
	}

	public setNotes(notes: string) {
		this.notes = notes;
	}

	public getContactName() {
		return this.contactName;
	}

	public setContactName(contactName: string) {
		this.contactName = contactName;
	}

	public getContactNumber() {
		return this.contactNumber;
	}

	public setContactNumber(contactNumber: string) {
		this.contactNumber = contactNumber;
	}

	public getCprStatus() {
		return this.cprStatusId;
	}

	public setCprStatus(cprStatusId: CprStatus) {
		this.cprStatusId = cprStatusId;
	}

	public getCprStatusLabel() {
		return CprStatusLabels[this.cprStatusId];
	}

	public getApprovalIndicator() {
		return this.approvalIndicator;
	}

	public setApprovalIndicator(approvalIndicator: CprApprovalIndicator) {
		this.approvalIndicator = approvalIndicator;
	}

	public getLineQty(): number {
		return this.lineQty;
	}

	public setLineQty(newLineQty: number) {
		this.lineQty = newLineQty;
	}

	public getCompany(): string | null {
		return this.company;
	}

	public setCompany(newCompany: string | null) {
		this.company = newCompany;
	}

	public getDaylightSavings(): string {
		return this.daylightSavings;
	}

	public setDaylightSavings(newDaylightSavings: string) {
		this.daylightSavings = newDaylightSavings;
	}

	public getTimeZone(): string {
		return this.timeZone;
	}

	public setTimeZone(newTimeZone: string) {
		this.timeZone = newTimeZone;
	}

	public getAosCols() {
		return this.aosCols.slice();
	}

	public getAosColEntries() {
		return Object.entries(this.aosColsByKey) as Array<[AosNodeType, AosCol | undefined]>;
	}

	public makeAosCol(aosNodeTypeId: AosNodeType): AosCol {
		if (aosNodeTypeId in this.aosColsByKey) {
			return this.aosColsByKey[aosNodeTypeId]!;
		}

		let aosCol: AosCol;

		switch (aosNodeTypeId) {
			case AosNodeType.AreaCode:
				aosCol = this.aos.areaCodes;
				break;
			case AosNodeType.Lata:
				aosCol = this.aos.latas;
				break;
			case AosNodeType.Network:
				aosCol = this.aos.network;
				break;
			case AosNodeType.State:
				aosCol = this.aos.states;
				break;
		}

		this.aosCols.push(aosCol);
		this.aosColsByKey[aosCol.aosNodeTypeId] = aosCol;

		return aosCol;
	}

	public hasAosCol(aosNodeTypeId: AosNodeType): boolean {
		if (aosNodeTypeId in this.aosColsByKey) {
			return true;
		}
		return false;
	}

	public deleteAosLabel(delName: string): boolean {
		if (!(delName in this.aosLabelsByKey)) {
			return false;
		}

		// @TODO loop through aos or known labeled aos and unset label reference.

		delete this.aosLabelsByKey[delName];

		for (let idx = 0; idx < this.aosLabels.length; idx++) {
			if (this.aosLabels[idx].getName() === delName) {
				this.aosLabels.splice(idx, 1);
				break;
			}
		}

		return true;
	}

	public hasAosLabel(name: string): boolean {
		return (name in this.aosLabelsByKey);
	}

	public getAosLabel(name: string): AosLbl | null {
		if (!(name in this.aosLabelsByKey)) {
			return null;
		}
		return this.aosLabelsByKey[name];
	}

	public getAosLabels(): AosLbl[] {
		return this.aosLabels;
	}

	public hasAosLabels(): boolean {
		return this.aosLabels.length > 0;
	}

	public makeAosLabel(aosNodeTypeId: AosNodeType, name: string): AosLbl | null {
		if (name in this.aosLabelsByKey) {
			return null;
		}

		let label: AosLbl | null = null;

		switch (aosNodeTypeId) {
			case AosNodeType.AreaCode:
				label = new AosLblAreaCode(this, name);
				break;
			case AosNodeType.Lata:
				label = new AosLblLata(this, name);
				break;
			case AosNodeType.State:
				label = new AosLblState(this, name);
				break;
		}

		if (!label) {
			return label;
		}

		this.aosLabels.push(label);
		this.aosLabelsByKey[name] = label;

		return label;
	}

	public addAosLerg(cprLerg: ICprLerg) {
		switch (cprLerg.cprNodeTypeId) {
			case CprNodeType.AreaCode:
			case CprNodeType.SixDigit:
				this.aosLergKeys[`AC:${cprLerg.somosValue.substring(0, 3)}`] = true;
				break;
		}

		if (cprLerg.state && cprLerg.state !== 'XX') {
			this.aosLergKeys[`ST:${cprLerg.state}`] = true;
		}
		if (cprLerg.lata && cprLerg.lata.substring(0, 3) !== '999') {
			this.aosLergKeys[`LT:${cprLerg.lata.substring(0, 3)}`] = true;
		}
	}

	public setAosLerg() {
		this.aosLergKeys = {};

		const areaCodes = this.aos.areaCodes.getRawValues(true);
		const latas = this.aos.latas.getRawValues(true);
		const networks = this.aos.network.getRawValues(true) as unknown as AosNetwork[];
		const states = this.aos.states.getRawValues(true);

		for (const cprLerg of CprLergCache.CprLerg) {
			for (const aosNetwork of networks) {
				switch (aosNetwork) {
					case AosNetwork.Canada:
						if (cprLerg.pointId === CprLergPoint.Canada) {
							this.addAosLerg(cprLerg);
							continue;
						}
						break;
					case AosNetwork.Caribbean:
						if (cprLerg.pointId === CprLergPoint.Caribbean) {
							this.addAosLerg(cprLerg);
							continue;
						}
						break;
					case AosNetwork.UnitedStates:
						if (
							cprLerg.pointId === CprLergPoint.UnitedStates
							|| cprLerg.pointId === CprLergPoint.Alaska
							|| cprLerg.pointId === CprLergPoint.Hawaii
						) {
							this.addAosLerg(cprLerg);
							continue;
						}
						break;
					case AosNetwork.UnitedStatesCanada:
						if (
							cprLerg.pointId === CprLergPoint.UnitedStates
							|| cprLerg.pointId === CprLergPoint.Alaska
							|| cprLerg.pointId === CprLergPoint.Hawaii
							|| cprLerg.pointId === CprLergPoint.Canada
						) {
							this.addAosLerg(cprLerg);
							continue;
						}
						break;
					case AosNetwork.UnitedStatesCaribbean:
						if (
							cprLerg.pointId === CprLergPoint.UnitedStates
							|| cprLerg.pointId === CprLergPoint.Alaska
							|| cprLerg.pointId === CprLergPoint.Hawaii
							|| cprLerg.pointId === CprLergPoint.Caribbean
						) {
							this.addAosLerg(cprLerg);
							continue;
						}
						break;
					case AosNetwork.UnitedStatesCanadaCaribbean:
						// everyhwere
						this.addAosLerg(cprLerg);
						continue;
				}
			}

			if (states.indexOf(cprLerg.state) >= 0) {
				this.addAosLerg(cprLerg);
				continue;
			}

			if (latas.indexOf(cprLerg.lata) >= 0) {
				this.addAosLerg(cprLerg);
			}

			switch (cprLerg.cprNodeTypeId) {
				case CprNodeType.AreaCode:
				case CprNodeType.NpaNxx: // technically doesn't exist (only SixDigit)
				case CprNodeType.SixDigit:
					const areaCode = cprLerg.somosValue.substring(0, 3);
					if (areaCodes.indexOf(areaCode) >= 0) {
						this.addAosLerg(cprLerg);
						continue;
					}
			}
		}
	}

	public isInAos(aosLergKey: string): boolean {
		return (aosLergKey in this.aosLergKeys);
	}

	public addInterLataCarrier(cic: string): boolean {
		if (this.areCarriersInSync()) {
			if (!this.intraLataCarriers.includes(cic)) {
				this.intraLataCarriers.push(cic);
			}
		}

		if (this.interLataCarriers.includes(cic)) {
			return false;
		}

		this.interLataCarriers.push(cic);

		return true;
	}

	public getInterLataCarriers() {
		return this.interLataCarriers.slice();
	}

	public setInterLataCarriers(cics: string[]) {
		const newCarriers = this.uniqueAndSorted(cics);

		if (this.areCarriersInSync()) {
			this.intraLataCarriers = [...newCarriers];
		}

		this.interLataCarriers = [...newCarriers];

		this.resetCarrierPossibles();
		this.validate();
	}

	public addIntraLataCarrier(cic: string): boolean {
		if (this.areCarriersInSync()) {
			if (!this.interLataCarriers.includes(cic)) {
				this.interLataCarriers.push(cic);
			}
		}

		if (this.intraLataCarriers.includes(cic)) {
			return false;
		}

		this.intraLataCarriers.push(cic);

		return true;
	}

	public getIntraLataCarriers() {
		return this.intraLataCarriers.slice();
	}

	public setIntraLataCarriers(cics: string[]) {
		const newCarriers = this.uniqueAndSorted(cics);

		if (this.areCarriersInSync()) {
			this.interLataCarriers = [...newCarriers];
		}

		this.intraLataCarriers = [...newCarriers];

		this.resetCarrierPossibles();
		this.validate();
	}

	public resetCarrierPossibles() {
		for (const cprRow of this.cprRows) {
			const cprCol = cprRow.getCprCol(CprNodeType.Carrier);

			if (!cprCol) {
				continue;
			}

			const useCol = cprCol as CprColCarrier;

			useCol.resetPossibles();
		}
	}

	public areCarriersInSync() {
		return this.areCarriersCurrentlyInSync;
	}

	/**
	 * Are carriers N-Sync?
	 * https://www.youtube.com/watch?v=RErREj1K7rc
	 */
	public determineAndSetIfCarriersAreInSync() {
		if (this.interLataCarriers.length !== this.intraLataCarriers.length) {
			this.areCarriersCurrentlyInSync = false;
			return;
		}

		//Sort and join carriers for string comparison
		const interCarriersStr = [...this.interLataCarriers].sort().join(',');
		const intraCarriersStr = [...this.intraLataCarriers].sort().join(',');

		//String comparison FTW
		this.areCarriersCurrentlyInSync = interCarriersStr === intraCarriersStr;

		this.validate();
	}

	public toggleCarriersInSync() {
		if (this.areCarriersCurrentlyInSync) {
			this.removeCarriersSync();
		} else {
			this.syncCarriers();
		}
	}

	public syncCarriers() {
		this.areCarriersCurrentlyInSync = true;

		const newCarriers = this.uniqueAndSorted([
			...this.interLataCarriers,
			...this.intraLataCarriers
		]);

		this.interLataCarriers = [...newCarriers];
		this.intraLataCarriers = [...newCarriers];

		this.validate();
	}

	public removeCarriersSync() {
		this.areCarriersCurrentlyInSync = false;

		this.validate();
	}

	public deleteCprRow(idx: number) {
		if (idx < 0 || idx >= this.cprRows.length) {
			return;
		}

		const deletedRow = this.cprRows.splice(idx, 1)[0];

		for (let reIdx = 0; reIdx < this.cprRows.length; reIdx++) {
			this.cprRows[reIdx].setCprIdx(reIdx);
		}

		this.validate();

		return deletedRow;
	}

	public moveCprRow(fromIdx: number, toIdx: number) {
		if (fromIdx < 0 || toIdx >= this.cprRows.length) {
			return;
		}

		//remove moving cprRow
		const cprRowToMove = this.cprRows.splice(fromIdx, 1).pop()!;
		//push back cprRow in new index
		this.cprRows.splice(toIdx, 0, cprRowToMove);

		//Recompute row indexes
		for (let idx = 0; idx < this.cprRows.length; idx++) {
			this.cprRows[idx].setCprIdx(idx);
		}

		this.validate();

		return cprRowToMove;
	}

	public deleteCprCol(cprNodeTypeId: CprNodeType) {
		const cprColToDeleteIndex = this.cprCols.findIndex(
			(cprCol) => cprCol.cprNodeTypeId === cprNodeTypeId
		);

		// node type not found
		if (cprColToDeleteIndex < 0) {
			return;
		}

		this.allRow.deleteCprCol(cprNodeTypeId);

		for (const cprRow of this.cprRows) {
			cprRow.deleteCprCol(cprNodeTypeId);
		}

		delete this.cprColsByKey[cprNodeTypeId];
		this.cprCols.splice(cprColToDeleteIndex, 1);

		for (let idx = 0; idx < this.cprCols.length; idx++) {
			this.cprCols[idx].setCprIdx(idx);
		}

		return this.validate();
	}

	public moveCprCol(fromIdx: number, toIdx: number) {
		const cprNodeTypeId = this.cprCols[fromIdx]?.cprNodeTypeId;

		// node type not found
		if (!cprNodeTypeId) {
			return;
		}

		// out of bounds
		if (toIdx < 0 || toIdx >= this.cprCols.length) {
			return;
		}

		// remove moving cprCol
		const cprColToMove = this.cprCols.splice(fromIdx, 1)[0];
		// push back cprCol in new index
		this.cprCols.splice(toIdx, 0, cprColToMove);

		for (let idx = 0; idx < this.cprCols.length; idx++) {
			this.cprCols[idx].setCprIdx(idx);
		}

		this.allRow.moveCprCol(cprNodeTypeId, toIdx);

		for (const cprRow of this.cprRows) {
			cprRow.moveCprCol(cprNodeTypeId, toIdx);
		}

		this.validate();

		return cprColToMove;
	}

	/**
	 * WARNING: Dangerous method. Should only be used
	 * from a CprLbl.setName to update this.cprLabelsByKey
	 */
	public updateCprLabelByKey(oldName: string, newName: string): boolean {
		if (!(oldName in this.cprLabelsByKey)) {
			return false;
		}

		const label = this.cprLabelsByKey[oldName];

		delete this.cprLabelsByKey[oldName];

		this.cprLabelsByKey[newName] = label;

		this.validate();
		return true;
	}

	public moveCprLabel(fromIdx: number, toIdx: number) {
		//Out of bounds
		if (fromIdx < 0 || toIdx >= this.cprLabels.length) {
			return;
		}

		//remove moving cprCol
		const labelToMove = this.cprLabels.splice(fromIdx, 1).pop()!;
		//push back cprCol in new index
		this.cprLabels.splice(toIdx, 0, labelToMove);

		this.resetCprLabelIndexes();

		this.validate();

		return labelToMove;
	}

	public resetCprLabelIndexes() {
		for (let idx = 0; idx < this.cprLabels.length; idx++) {
			this.cprLabels[idx].setCprIdx(idx);
		}
	}

	public makeCprCol(cprNodeTypeId: CprNodeType, cprIdx = this.cprCols.length): CprCol {
		if (cprNodeTypeId in this.cprColsByKey) {
			return this.cprColsByKey[cprNodeTypeId]!;
		}

		cprIdx = Math.min(cprIdx, this.cprCols.length);

		const cprCol = this.allRow.makeCprCol(cprNodeTypeId, cprIdx);

		this.cprCols.splice(cprIdx, 0, cprCol);
		this.cprColsByKey[cprNodeTypeId] = cprCol;

		for (let reIdx = 0; reIdx < this.cprCols.length; reIdx++) {
			this.cprCols[reIdx].setCprIdx(reIdx);
		}

		for (const cprRow of this.cprRows) {
			cprRow.makeCprCol(cprNodeTypeId, cprIdx);
		}

		this.validate();

		return cprCol;
	}

	public getCprColNodeTypes() {
		return Object.keys(this.cprColsByKey) as CprNodeType[];
	}

	public getCprRowByIndex(idx: number): CprRow | null {
		return this.cprRows[idx] ?? null;
	}

	public getCprRows() {
		return this.cprRows;
	}

	public hasCprRows() {
		return this.cprRows.length > 0;
	}

	public getCprCols() {
		return this.cprCols;
	}

	public hasCprCol(cprNodeTypeId: CprNodeType): boolean {
		if (cprNodeTypeId in this.cprColsByKey) {
			return true;
		}
		return false;
	}

	public hasCprCols(): boolean {
		return this.cprCols.length > 0;
	}

	public makeCprRow(
		cprIdx = this.cprRows.length,
		setAsNew = false,
		cloneValuesFrom: CprRow | undefined = undefined
	): CprRow {
		cprIdx = Math.min(cprIdx, this.cprRows.length);

		const cprRow = new CprRow(this, cprIdx);

		if (setAsNew) {
			cprRow.setIsRowNew(setAsNew);
		}

		if (cloneValuesFrom) {
			cprRow.cloneValuesFrom(cloneValuesFrom);
		}

		if (cprIdx >= this.cprRows.length) {
			this.cprRows.push(cprRow);
			return cprRow;
		}

		this.cprRows.splice(cprIdx, 0, cprRow);

		for (let reIdx = cprIdx; reIdx < this.cprRows.length; reIdx++) {
			this.cprRows[reIdx].setCprIdx(reIdx);
		}

		this.validate();

		return cprRow;
	}

	/**
	 * Updates the following:
	 * - interLataCarriers
	 * - intraLataCarriers
	 * - cpr rows
	 * - cpr labels
	 */
	public bulkUpdateCarriers(oldCic: string, newCic: string, dryRun: boolean): ICprBulkCarrierResponse {
		const response: ICprBulkCarrierResponse = {
			modifiedCprRowsQty: 0,
			modifiedLabelsQty: 0
		};

		const replaceOldCicInArr = (cics: string[]) => {
			const cicSet = new Set(cics);

			if (cicSet.has(oldCic)) {
				cicSet.delete(oldCic);
				cicSet.add(newCic);
			}

			return Array.from(cicSet);
		};

		const newInterLataCarriers = replaceOldCicInArr(this.interLataCarriers);
		const newIntraLataCarriers = replaceOldCicInArr(this.intraLataCarriers);

		if (!dryRun) {
			this.interLataCarriers = newInterLataCarriers;
			this.intraLataCarriers = newIntraLataCarriers;
		}

		for (const carrierRow of this.cprRows) {
			response.modifiedCprRowsQty += carrierRow.bulkUpdateCarriers(oldCic, newCic, dryRun);
		}

		for (const cprLabel of this.cprLabels) {
			response.modifiedLabelsQty += cprLabel.bulkUpdateCarriers(oldCic, newCic, dryRun);
		}

		this.setInterLataCarriers(newInterLataCarriers);
		this.setIntraLataCarriers(newIntraLataCarriers);

		if (!dryRun) {
			this.validate();
		}

		return response;
	}

	public isHighlighting(): boolean {
		if (this.highlightNodeTypeId && this.highlightValue) {
			return true;
		}

		if (this.highlightCic) {
			return true;
		}

		return false;
	}

	public getHighlightValue() {
		return this.highlightValue;
	}

	public setHighlightValue(cprNodeTypeId: CprNodeType | undefined, value: string | undefined, cic: string | undefined) {
		this.highlightKeys = {};
		this.highlightNodeTypeId = cprNodeTypeId;
		this.highlightValue = value;
		this.highlightCic = cic;

		this.selectedHighlightedRow = undefined;
		this.highlightedRows = [];
		this.selectedHighlightedLabel = undefined;
		this.highlightedLabels = [];

		// console.error(`setHighlightValue: ${cprNodeTypeId}, value: ${value}, cic: ${cic}`);

		//is a label
		if (value?.startsWith('*')) {
			this.validate();
			return;
		}

		if (!this.highlightNodeTypeId || !this.highlightValue) {
			this.validate();
			return;
		}

		let useCprLerg: ICprLerg | null = null;

		switch (this.highlightNodeTypeId) {
			case CprNodeType.AreaCode:
				if (!(this.highlightValue in CprLergCache.CprLergByNpa)) {
					break;
				}
				useCprLerg = CprLergCache.CprLergByNpa[this.highlightValue];
				break;

			case CprNodeType.Lata:
				if (this.highlightValue in CprLergCache.CprLergByLata) {
					useCprLerg = CprLergCache.CprLergByLata[this.highlightValue];
				}
				break;

			case CprNodeType.NpaNxx:
			case CprNodeType.SixDigit:
				if (this.highlightValue in CprLergCache.CprLergByNpaNxx) {
					useCprLerg = CprLergCache.CprLergByNpaNxx[this.highlightValue];
				}
				break;

			case CprNodeType.State:
				if (this.highlightValue in CprLergCache.CprLergByState) {
					useCprLerg = CprLergCache.CprLergByState[this.highlightValue];
				}
				break;

			case CprNodeType.TenDigit:
				const npaNxxValue = this.highlightValue.substring(0, 6);
				if (npaNxxValue in CprLergCache.CprLergByNpaNxx) {
					useCprLerg = CprLergCache.CprLergByNpaNxx[this.highlightValue];
				}
				break;
		}

		if (!useCprLerg) {
			this.validate();
			return;
		}

		for (const possibleCprLerg of CprLergCache.CprLerg) {

			switch (this.highlightNodeTypeId) {

				case CprNodeType.AreaCode:
					switch (possibleCprLerg.cprNodeTypeId) {
						case CprNodeType.AreaCode:
							if (possibleCprLerg.somosValue === this.highlightValue) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Exact;
							}
							break;
						case CprNodeType.Lata:
							if (this.highlightParents && possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.NpaNxx:
						case CprNodeType.SixDigit:
							if (this.highlightChildren && possibleCprLerg.somosValue.indexOf(this.highlightValue) === 0) {
								this.highlightKeys[`${CprNodeType.NpaNxx}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
								this.highlightKeys[`${CprNodeType.SixDigit}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
						case CprNodeType.State:
							if (this.highlightParents && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.TenDigit:
							if (this.highlightChildren && possibleCprLerg.somosValue.indexOf(this.highlightValue) === 0) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;

					}
					break;

				case CprNodeType.Lata:
					switch (possibleCprLerg.cprNodeTypeId) {
						case CprNodeType.AreaCode:
							if (this.highlightChildren && possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
						case CprNodeType.Lata:
							if (possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Exact;
							}
							break;
						case CprNodeType.NpaNxx:
						case CprNodeType.SixDigit:
							if (this.highlightChildren && possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${CprNodeType.NpaNxx}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
								this.highlightKeys[`${CprNodeType.SixDigit}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
						case CprNodeType.State:
							if (this.highlightParents && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.TenDigit:
							if (this.highlightChildren && possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
					}
					break;

				case CprNodeType.NpaNxx:
				case CprNodeType.SixDigit:
					switch (possibleCprLerg.cprNodeTypeId) {
						case CprNodeType.AreaCode:
							if (this.highlightParents && this.highlightValue.indexOf(possibleCprLerg.somosValue) === 0) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.Lata:
							if (this.highlightParents && possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.NpaNxx:
						case CprNodeType.SixDigit:
							if (possibleCprLerg.somosValue === this.highlightValue) {
								this.highlightKeys[`${CprNodeType.NpaNxx}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Exact;
								this.highlightKeys[`${CprNodeType.SixDigit}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Exact;
							}
							break;
						case CprNodeType.State:
							if (this.highlightParents && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.TenDigit:
							if (this.highlightChildren && possibleCprLerg.somosValue.indexOf(this.highlightValue) === 0) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
					}
					break;

				case CprNodeType.State:
					switch (possibleCprLerg.cprNodeTypeId) {
						case CprNodeType.AreaCode:
						case CprNodeType.Lata:
							if (this.highlightChildren && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
						case CprNodeType.NpaNxx:
						case CprNodeType.SixDigit:
							if (this.highlightChildren && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${CprNodeType.NpaNxx}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
								this.highlightKeys[`${CprNodeType.SixDigit}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
						case CprNodeType.State:
							if (possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Exact;
							}
							break;
						case CprNodeType.TenDigit:
							if (this.highlightChildren && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Child;
							}
							break;
					}
					break;

				case CprNodeType.TenDigit:
					switch (possibleCprLerg.cprNodeTypeId) {
						case CprNodeType.AreaCode:
							if (this.highlightParents && this.highlightValue.indexOf(possibleCprLerg.somosValue) === 0) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.Lata:
							if (this.highlightParents && possibleCprLerg.lata === useCprLerg.lata) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.NpaNxx:
						case CprNodeType.SixDigit:
							if (this.highlightParents && this.highlightValue.indexOf(possibleCprLerg.somosValue) === 0) {
								this.highlightKeys[`${CprNodeType.NpaNxx}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
								this.highlightKeys[`${CprNodeType.SixDigit}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.State:
							if (this.highlightParents && possibleCprLerg.state === useCprLerg.state) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Parent;
							}
							break;
						case CprNodeType.TenDigit:
							if (possibleCprLerg.somosValue.indexOf(this.highlightValue) === 0) {
								this.highlightKeys[`${possibleCprLerg.cprNodeTypeId}:${possibleCprLerg.somosValue}`] = CprValueHighlightType.Exact;
							}
							break;
					}
					break;
			}
		}

		this.validate();

		return;
	}

	public getHighlightChildren(): boolean {
		return this.highlightChildren;
	}

	public getHighlightParents(): boolean {
		return this.highlightParents;
	}

	public setHighlightChildren(shouldHighlight: boolean): boolean {
		this.highlightChildren = shouldHighlight;
		if (this.highlightNodeTypeId && this.highlightValue) {
			this.validate();
		}
		return this.highlightChildren;
	}

	public setHighlightParents(shouldHighlight: boolean): boolean {
		this.highlightParents = shouldHighlight;
		if (this.highlightNodeTypeId && this.highlightValue) {
			this.validate();
		}
		return this.highlightParents;
	}

	public shouldHighlightValue(cprNodeTypeId: CprNodeType, value: string): CprValueHighlightType | undefined {
		if (!this.highlightNodeTypeId || !this.highlightValue) {
			return undefined;
		}

		const highlightKey = `${cprNodeTypeId}:${value}`;

		if (highlightKey in this.highlightKeys) {
			return this.highlightKeys[highlightKey];
		}

		return undefined;
	}

	public isSelectedHighlightedRow(cprRow: CprRow) {
		return this.selectedHighlightedRow?.getCprIdx() === cprRow.getCprIdx();
	}

	public getHighlightedRows() {
		return [...this.highlightedRows];
	}

	public getSelectedHighlightedRowIndex() {
		if (!this.selectedHighlightedRow) {
			return -1;
		}

		return this.highlightedRows.indexOf(this.selectedHighlightedRow);
	}

	public getSelectedHighlightedRow() {
		return this.selectedHighlightedRow;
	}

	public selectNextHighlightedRow() {
		if (this.highlightedRows.length <= 0 || !this.selectedHighlightedRow) {
			return;
		}

		const currentIndex = this.highlightedRows.indexOf(
			this.selectedHighlightedRow
		);
		const isLastElement = currentIndex === this.highlightedRows.length - 1;

		// bounds check
		if (isLastElement) {
			return;
		}

		this.selectedHighlightedRow = this.highlightedRows[currentIndex + 1];

		return this.selectedHighlightedRow;
	}

	public selectPreviousHighlightedRow() {
		if (this.highlightedRows.length <= 0 || !this.selectedHighlightedRow) {
			return;
		}

		const currentIndex = this.highlightedRows.indexOf(
			this.selectedHighlightedRow
		);
		const isFirstElement = currentIndex === 0;

		// bounds check
		if (isFirstElement) {
			return;
		}

		this.selectedHighlightedRow = this.highlightedRows[currentIndex - 1];

		return this.selectedHighlightedRow;
	}

	public isSelectedHighlightedLabel(cprLbl: CprLbl) {
		return this.selectedHighlightedLabel?.getCprIdx() === cprLbl.getCprIdx();
	}

	public getHighlightedLabels() {
		return [...this.highlightedLabels];
	}

	public getSelectedHighlightedLabelIndex() {
		if (!this.selectedHighlightedLabel) {
			return -1;
		}

		return this.highlightedLabels.indexOf(this.selectedHighlightedLabel);
	}

	public getSelectedHighlightedLabel() {
		return this.selectedHighlightedLabel;
	}

	public selectNextHighlightedLabel() {
		if (this.highlightedLabels.length <= 0 || !this.selectedHighlightedLabel) {
			return;
		}

		const currentIndex = this.highlightedLabels.indexOf(
			this.selectedHighlightedLabel
		);
		const isLastElement = currentIndex === this.highlightedLabels.length - 1;

		// bounds check
		if (isLastElement) {
			return;
		}

		this.selectedHighlightedLabel = this.highlightedLabels[currentIndex + 1];

		return this.selectedHighlightedLabel;
	}

	public selectPreviousHighlightedLabel() {
		if (this.highlightedLabels.length <= 0 || !this.selectedHighlightedLabel) {
			return;
		}

		const currentIndex = this.highlightedLabels.indexOf(
			this.selectedHighlightedLabel
		);
		const isFirstElement = currentIndex === 0;

		// bounds check
		if (isFirstElement) {
			return;
		}

		this.selectedHighlightedLabel = this.highlightedLabels[currentIndex - 1];

		return this.selectedHighlightedLabel;
	}

	public deleteCprLabel(delName: string): boolean {
		if (!(delName in this.cprLabelsByKey)) {
			return false;
		}

		// @TODO loop through rows or known labeled rows and unset label reference.
		// Paden -- do we though?

		delete this.cprLabelsByKey[delName];

		for (let idx = 0; idx < this.cprLabels.length; idx++) {
			if (this.cprLabels[idx].getName() === delName) {
				this.cprLabels.splice(idx, 1);
				idx--; // compensate for removing an element
				continue;
			}

			//reset new indexes
			this.cprLabels[idx].setCprIdx(idx);
		}

		this.validate();

		return true;
	}

	public hasCprLabel(name: string): boolean {
		return (name in this.cprLabelsByKey);
	}

	public getCprLabel(name: string): CprLbl | null {
		if (!(name in this.cprLabelsByKey)) {
			return null;
		}
		return this.cprLabelsByKey[name];
	}

	public hasCprLabels(): boolean {
		return this.cprLabels.length > 0;
	}

	public getCprLabels(cprNodeTypeId?: CprNodeType): CprLbl[] {
		const labelsCopy = [];

		for (const cprLabel of this.cprLabels) {
			if (typeof cprNodeTypeId !== 'undefined') {
				if (cprLabel.cprNodeTypeId !== cprNodeTypeId) {
					continue;
				}
			}

			labelsCopy.push(cprLabel);
		}

		return labelsCopy;
	}

	public makeCprLabel(cprNodeTypeId: CprNodeType, name: string, idx = this.cprLabels.length, setAsIsNew = false): CprLbl | null {
		if (name in this.cprLabelsByKey) {
			return null;
		}

		let label: CprLbl | null = null;

		switch (cprNodeTypeId) {
			case CprNodeType.AreaCode:
				label = new CprLblAreaCode(this, name);
				break;
			case CprNodeType.Date:
				label = new CprLblDate(this, name);
				break;
			case CprNodeType.DayOfWeek:
				label = new CprLblDayOfWeek(this, name);
				break;
			case CprNodeType.Lata:
				label = new CprLblLata(this, name);
				break;
			case CprNodeType.NpaNxx:
				label = new CprLblNpaNxx(this, name);
				break;
			case CprNodeType.SixDigit:
				label = new CprLblSixDigit(this, name);
				break;
			case CprNodeType.State:
				label = new CprLblState(this, name);
				break;
			case CprNodeType.TenDigit:
				label = new CprLblTenDigit(this, name);
				break;
			case CprNodeType.Time:
				label = new CprLblTimeOfDay(this, name);
				break;
		}

		if (!label) {
			return label;
		}

		//Min = 0; max = length
		idx = Math.max(
			Math.min(idx, this.cprLabels.length),
			0
		);

		this.cprLabelsByKey[name] = label;
		this.cprLabels.splice(idx, 0, label);
		this.resetCprLabelIndexes();

		if (setAsIsNew) {
			label.setIsLabelNew(setAsIsNew);
		}

		this.validate();

		return label;
	}

	public getSourceEffectiveTs(): Date | null {
		// return a new date so it can't be changed directly.
		// the point of having an accessor method is to prevent the value from changing.
		// if we return a Date object, the value itself is modifiable Date.setMinute(0), etc...
		return (this.sourceEffectiveTs) ? new Date(this.sourceEffectiveTs.getTime()) : null;
	}

	public getSourceRecVersionid(): string | null {
		return this.sourceRecVersionId;
	}

	public getTargetEffectiveTs(): Date | null {
		// return a new date so it can't be changed directly.
		// the point of having an accessor method is to prevent the value from changing.
		// if we return a Date object, the value itself is modifiable Date.setMinute(0), etc...
		return (this.targetEffectiveTs) ? new Date(this.targetEffectiveTs.getTime()) : null;
	}

	public setTargetEffectiveTs(newEffTs: Date | null): Date | null {
		// copy the date value into a new object.
		// do not return the same object or targetEffectiveTs, so it cannot be modified.
		this.targetEffectiveTs = (newEffTs) ? new Date(newEffTs.getTime()) : null;
		return this.getTargetEffectiveTs();
	}

	public addError(cprError: CprError) {
		this.errors.push(cprError);
	}

	public getErrors(cprErrorTypeId: CprErrorType | null = null): CprError[] {
		if (cprErrorTypeId === null) {
			return this.errors;
		}

		const errorsOfType: CprError[] = [];

		for (const cprError of this.errors) {
			if (cprError.cprErrorTypeId === cprErrorTypeId) {
				errorsOfType.push(cprError);
			}
		}

		return errorsOfType;
	}

	public hasErrors(cprErrorTypeId: CprErrorType | null = null): boolean {
		if (cprErrorTypeId === null) {
			return this.errors.length > 0;
		}

		for (const cprError of this.errors) {
			if (cprError.cprErrorTypeId === cprErrorTypeId) {
				return true;
			}
		}

		return false;
	}

	public clean(config: ICleanConfigRequest): ICprCleanResponse {
		const {
			maxIterations = this.maxCleanIterations,
		} = config;

		const response: ICprCleanResponse = {
			removedCprRowsQty: 0,
			modifiedCprRowsQty: 0,
			//labels
			removedCprLabelsQty: 0,
			modifiedCprLabelsQty: 0,
			//admin
			removedInterCarriersQty: 0,
			removedIntraCarriersQty: 0
		};

		let cleanDidOccur = false;

		this.setValidation(true);
		this.setValidation(false);
		if (!this.hasErrors()) {
			return response;
		}

		for (const cprRow of this.cprRows) {
			const cleanRes = cprRow.clean();

			if (cleanRes.wasRemoved) {
				response.removedCprRowsQty++;
				cleanDidOccur = true;
			} else if (cleanRes.removedQty > 0 || cleanRes.addedQty > 0) {
				response.modifiedCprRowsQty++;
				cleanDidOccur = true;
			}
		}

		for (const label of this.cprLabels) {
			const cleanRes = label.clean(config.removeEmptyLabels);

			if (cleanRes.wasRemoved) {
				response.removedCprLabelsQty++;
				cleanDidOccur = true;
			} else if (cleanRes.removedQty > 0 || cleanRes.addedQty > 0) {
				response.modifiedCprLabelsQty++;
				cleanDidOccur = true;
			}
		}

		if (config.removeUnusedLabels) {
			const unusedLabels = this.getUnusedLabels();

			for (const unusedLabel of unusedLabels) {
				this.deleteCprLabel(unusedLabel.getName());
				response.removedCprLabelsQty++;
				cleanDidOccur = true;
			}
		}

		// if clean was performed then do another iterations
		if (cleanDidOccur && maxIterations > 0) {
			const nextClean = this.clean({
				removeEmptyLabels: config.removeEmptyLabels,
				removeUnusedLabels: config.removeUnusedLabels,
				maxIterations: maxIterations - 1
			});

			response.modifiedCprLabelsQty += nextClean.modifiedCprLabelsQty;
			response.removedCprLabelsQty += nextClean.removedCprLabelsQty;

			response.removedCprRowsQty += nextClean.removedCprRowsQty;
			response.modifiedCprRowsQty += nextClean.modifiedCprRowsQty;
		} else {
			const listOfCarriers = new Set<string>();

			/**
			 * Get list of carriers populated from cpr rows
			 */
			for (const cprRow of this.cprRows) {
				const rawValue = cprRow.carrier.getValue();
				if (rawValue) {
					listOfCarriers.add(rawValue);
				}
			}

			/**
			 * Remove unused carriers from inter
			 */
			for (const interCarrier of [...this.interLataCarriers]) {
				if (listOfCarriers.has(interCarrier)) {
					continue;
				}

				const indexOfCarrier = this.interLataCarriers.indexOf(interCarrier);

				if (indexOfCarrier >= 0) {
					this.interLataCarriers.splice(indexOfCarrier, 1);
					response.removedInterCarriersQty++;
				}
			}

			/**
			 * Remove unused carriers from intra
			 */
			for (const intraCarrier of [...this.intraLataCarriers]) {
				if (listOfCarriers.has(intraCarrier)) {
					continue;
				}

				const indexOfCarrier = this.intraLataCarriers.indexOf(intraCarrier);

				if (indexOfCarrier >= 0) {
					this.intraLataCarriers.splice(indexOfCarrier, 1);
					response.removedIntraCarriersQty++;
				}
			}

			//Set back validation to on
			//Also does validation
			this.setValidation(true);
		}


		return response;
	}

	/**
	 * Validates entire CPR: rows, labels, AOS, etc
	 *
	 * @fires this.invokeValidateListeners()
	 */
	public validate(): boolean {
		if (!this.isValidationEnabled) {
			return true;
		}

		// @TODO A CPR path cannot contain a populated AN and PC node.
		// @TODO #DIAL or may be empty if the call terminates to an Announcement node
		// @TODO Can't have CPR rows differ only in Action node (Carrier, AN, Tel#) values.

		// disable validation before validating (avoid potential recursion)
		this.isValidationEnabled = false;

		this.errors = [];

		if (this.cprRows.length === 0) {
			if (this.interLataCarriers.length > 1) {
				this.errors.push(new CprError(CprErrorType.Error, CprSection.CprSectionAdmin, 0, null, `Multiple Inter Carriers with no CPR rows.`));
			}

			if (this.intraLataCarriers.length > 1) {
				this.errors.push(new CprError(CprErrorType.Error, CprSection.CprSectionAdmin, 0, null, `Multiple Intra Carriers with no CPR rows.`));
			}
		}

		this.highlightedRows = [];
		this.highlightedLabels = [];

		this.selectedHighlightedRow = undefined;
		this.selectedHighlightedLabel = undefined;

		this.validateAosCols();
		this.validateAosLbls();
		this.validateCprLbls();
		this.validateCprTree();

		if (this.highlightedRows.length > 0) {
			this.selectedHighlightedRow = this.highlightedRows[0];
		}

		if (this.highlightedLabels.length > 0) {
			this.selectedHighlightedLabel = this.highlightedLabels[0];
		}

		this.invokeValidateListeners();

		// re-enable validation after validating
		this.isValidationEnabled = true;

		return this.hasErrors();
	}

	public findMatch(search: ICprRoutingSearch): ICprRoutingResult | null {
		const npa = search.tenDigitCpn.substring(0, 3);
		const npaNxx = search.tenDigitCpn.substring(0, 6);

		// @TODO check AOS.

		if (!this.sourceEffectiveTs) {
			return null;
		}

		if (!this.sourceRespOrgId) {
			return null;
		}

		// short circuit single cic routing with no CPR rows.
		if (this.cprRows.length === 0 && this.interLataCarriers.length === 1) {
			return {
				routingCacheKey: this.routingCacheKey,
				routingCacheTypeId: this.routingCacheTypeId,
				effectiveTs: this.sourceEffectiveTs,
				respOrgId: this.sourceRespOrgId,
				carrier: this.interLataCarriers[0],
				announcement: null,
				cprIdx: null,
				percent: null,
			};
		}

		for (const cprRow of this.cprRows) {
			let cprRowMatch: boolean = true;

			let usePercent: number | null = null;

			for (const cprCol of this.cprCols) {
				const useCol = cprRow.getCprCol(cprCol.cprNodeTypeId);

				if (!useCol) {
					cprRowMatch = false;
					break;
				}

				if (useCol.isOther()) {
					// isOther always matches
					continue;
				}

				const rawValues = useCol.getRawValues();

				if (rawValues.length === 0) {
					// do not match empty values
					continue;
				}

				switch (useCol.cprNodeTypeId) {
					case CprNodeType.AreaCode:
						cprRowMatch = useCol.matchesValue(npa);
						break;
					case CprNodeType.Lata:
						const useLata = search.lata.substring(0, 3); // HMM
						cprRowMatch = useCol.matchesValue(useLata);
						break;
					case CprNodeType.NpaNxx:
					case CprNodeType.SixDigit:
						cprRowMatch = useCol.matchesValue(npaNxx);
						break;
					case CprNodeType.State:
						cprRowMatch = useCol.matchesValue(search.state);
						break;
					case CprNodeType.TenDigit:
						cprRowMatch = useCol.matchesValue(search.tenDigitCpn);
						break;
					case CprNodeType.Percent:
						if (rawValues.length > 0) {
							usePercent = parseInt(rawValues[0], 10);
						}
						break;
					default:
					// do nothing, this is not a 'matching' column type.
				}

				// no need to check all columns once any column doesn't match
				if (!cprRowMatch) {
					break;
				}
			}

			if (!cprRowMatch) {
				continue;
			}

			return {
				routingCacheKey: this.routingCacheKey,
				routingCacheTypeId: this.routingCacheTypeId,
				effectiveTs: this.sourceEffectiveTs,
				respOrgId: this.sourceRespOrgId,
				carrier: cprRow.carrier.getValue(),
				announcement: cprRow.announcement.getValue(),
				cprIdx: cprRow.getCprIdx(),
				percent: usePercent,
			};
		}

		return null;
	}

	public toCprTwig(): CprTwig {

		const childNodeTypeIds: CprNodeType[] = [];

		for (const cprCol of this.cprCols) {
			if (!cprCol.isTreeNode) {
				continue;
			}
			childNodeTypeIds.push(cprCol.cprNodeTypeId);
		}

		const root: CprTwig = {
			cprRowIdx: null,
			cprNodeTypeId: null,
			cprValues: [],
			cic: null,
			announcement: null,
			terminatingNumber: null,
			percent: null,
			children: [],
		};

		if (this.cprRows.length === 0) {
			root.cic = this.interLataCarriers[0];
			return root;
		}

		const rootRow = this.cprRows[this.cprRows.length - 1];

		root.cprRowIdx = rootRow.getCprIdx();
		root.cprNodeTypeId = null;
		root.cic = rootRow.carrier.getValue();
		root.terminatingNumber = rootRow.terminatingNumber.getValue();
		root.announcement = rootRow.announcement.getValue();
		root.percent = rootRow.percent.getValue();

		const treeKeys: Record<string, CprTwig> = {};

		for (const cprRow of this.cprRows) {
			const rowIdx = cprRow.getCprIdx();

			if (rowIdx === root.cprRowIdx) {
				break;
			}

			let parent = root;
			let hasChildValues: boolean = false;

			const usePct = cprRow.percent.getValue();

			for (const cprCol of this.cprCols) {
				const useCol = cprRow.getCprCol(cprCol.cprNodeTypeId);

				if (!useCol) {
					// should not happen, type-safe check
					continue;
				}

				if (!useCol.isTreeNode) {
					continue;
				}

				if (useCol.isOther()) {
					// there can be isTreeNode values after 'OTHER' columns
					// parent.cprRowIdx = rowIdx;
					continue;
				}

				const rawValues = useCol.getExtValues(true);

				// rawValues.length check before isTermNodeCheck
				if (rawValues.length === 0) {
					continue;
				}

				// if we have values, and this is as terminating node, we're done.
				if (useCol.isTermNode) {
					break;
				}

				hasChildValues = true;

				const treeKey = `${useCol.cprNodeTypeId}:${rawValues.join(',')}`;

				if (treeKey in treeKeys) {
					parent = treeKeys[treeKey];
					hasChildValues = false;
					continue;
				}

				const newChild: CprTwig = {
					cprRowIdx: rowIdx,
					cprNodeTypeId: useCol.cprNodeTypeId,
					cprValues: rawValues,
					cic: null, // cprRow.carrier.getValue(),
					announcement: null, // cprRow.announcement.getValue(),
					terminatingNumber: null, // cprRow.terminatingNumber.getValue(),
					percent: usePct,
					children: [],
				};

				treeKeys[treeKey] = newChild;

				parent.children.push(newChild);
				parent = newChild;
				hasChildValues = false;
			}

			if (!hasChildValues) {
				parent.cic = cprRow.carrier.getValue();
				parent.announcement = cprRow.announcement.getValue();
				parent.terminatingNumber = cprRow.terminatingNumber.getValue();
			}

			// console.debug(`cprRowIdx: ${rowIdx}, p.idx: ${parent.cprRowIdx}, p.cic: ${parent.cic}, p.cprValues: ${parent.cprValues.length}, p.children: ${parent.children.length}, p.hasChildValues: ${hasChildValues}`);
		}

		return root;
	}

	private validateAosCols() {
		for (const aosCol of this.aosCols) {
			aosCol.validate();
		}
	}

	private validateAosLbls() {
		for (const aosLbl of this.aosLabels) {
			aosLbl.validate();
		}
	}

	private validateCprLbls() {
		for (const cprLbl of this.cprLabels) {
			cprLbl.validate();
			if (cprLbl.isHighlighted()) {
				this.highlightedLabels.push(cprLbl);
			}
		}

		const unusedLabels = this.getUnusedLabels();

		for (const unusedLabel of unusedLabels) {
			unusedLabel.addWarning(null, `Label ${unusedLabel.getName()} is not in use.`);
		}
	}

	private validateCprTree() {
		const root = new CprTreeNode(null, this.routingCacheKey, 0);

		const canadaCics: string[] = [];
		const canadaCicsByKey: { [key: string]: string[]; } = {};

		for (const cprRow of this.cprRows) {
			cprRow.setHighlightTypeId(undefined);

			cprRow.validate();

			const cprRowIdx = cprRow.getCprIdx();
			const cic = cprRow.carrier.getValue();
			let parent: CprTreeNode = root;
			let bestLergColHighlightTypeId: CprValueHighlightType | undefined;

			for (const cprCol of this.cprCols) {
				const useCol = cprRow.getCprCol(cprCol.cprNodeTypeId);

				if (!useCol) {
					// should not happen, type-safe check
					continue;
				}

				useCol.validate();

				if (useCol.isOther()) {
					continue;
				}

				if (useCol.isLergNode) {
					const lastLergColHighlightTypeId = useCol.getHighlightedTypeId();
					if (lastLergColHighlightTypeId) {
						// CprValueHighlightType enum should be Best = Lowest value.
						if (!bestLergColHighlightTypeId || lastLergColHighlightTypeId < bestLergColHighlightTypeId) {
							bestLergColHighlightTypeId = lastLergColHighlightTypeId;
						}
					}
				}

				if (!useCol.isTreeNode) {
					continue;
				}

				const extValues = useCol.getExtValues(true);

				if (extValues.length === 0) {
					// @TODO possible CprTreeNode sanity checks
					continue;
				}

				const cprLbl = useCol.getCprLabel();

				if (cprLbl) {
					const lastLergColHighlightTypeId = cprLbl.getHighlightedTypeId();

					if (lastLergColHighlightTypeId) {
						// CprValueHighlightType enum should be Best = Lowest value.
						if (!bestLergColHighlightTypeId || lastLergColHighlightTypeId < bestLergColHighlightTypeId) {
							bestLergColHighlightTypeId = lastLergColHighlightTypeId;
						}
					}

					// label names can change, use uuid as treeKey
					const treeKey = cprLbl.uuid;

					if (parent.hasChild(treeKey)) {
						// a prior row must use the same label
						continue;
					}

					// new values to set, make sure none of these values already exist on parent.
					for (const extVal of extValues) {
						const keyVal = `${useCol.cprNodeTypeId}:${extVal}`;

						const oldIdx = parent.getValueIdx(keyVal);

						if (oldIdx !== null) {
							this.errors.push(new CprError(CprErrorType.Warning, CprSection.CprSectionCpr, 0, extVal, `Duplicate ${useCol.cprNodeTypeId} label value ${extVal} (rows: ${oldIdx + 1}, ${cprRowIdx + 1}).`));
							continue;
						}

						parent.addValue(keyVal, cprRowIdx);
					}

					const child = new CprTreeNode(useCol.cprNodeTypeId, treeKey, cprRowIdx);
					parent.addChild(child);
					// if there are more columns on this row, this child will be their parent.
					parent = child;

					continue;
				}

				// values can change a lot, and value combinations can be shared across rows.
				// lata 512 is not the same as npa 512, so use cprNodeTypeId.
				const treeKey = `${useCol.cprNodeTypeId}:${extValues.join(',')}`;

				let child = parent.getChild(treeKey);

				if (child) {
					// a prior row must have the same values.
					// if there are more columns on this row, this child will be their parent.
					parent = child;
					continue;
				}

				// new values to set, make sure none of these values already exist on parent.
				for (const extVal of extValues) {
					if (extVal === 'OTHER') {
						continue;
					}

					const keyVal = `${useCol.cprNodeTypeId}:${extVal}`;

					const oldIdx = parent.getValueIdx(keyVal);

					if (oldIdx !== null) {
						this.errors.push(new CprError(CprErrorType.Error, CprSection.CprSectionCpr, 0, null, `Duplicate ${useCol.cprNodeTypeId} value ${extVal} (rows: ${oldIdx + 1}, ${cprRowIdx + 1}).`));
						continue;
					}

					parent.addValue(keyVal, cprRowIdx);
				}

				// create a new child
				child = new CprTreeNode(useCol.cprNodeTypeId, treeKey, cprRowIdx);
				parent.addChild(child);
				// if there are more columns on this row, this child will be their parent.
				parent = child;
			}

			// canada
			if (cprRow.hasCanada()) {
				const cic = cprRow.carrier.getValue();
				if (cic) {
					if (!(cic in canadaCicsByKey)) {
						canadaCics.push(cic);
						canadaCicsByKey[cic] = [];
					}
					canadaCicsByKey[cic].push(cprRow.uuid);
				}
			}

			if (this.highlightNodeTypeId && this.highlightValue) {
				// lerg highlighting
				if (!this.highlightCic || this.highlightCic === cic) {
					cprRow.setHighlightTypeId(bestLergColHighlightTypeId);
				}
			} else {
				// cic only
				if (this.highlightCic && this.highlightCic === cic) {
					cprRow.setHighlightTypeId(CprValueHighlightType.Exact);
				}
			}

			if (cprRow.isHighlighted()) {
				this.highlightedRows.push(cprRow);
			}
		}

		// check to make sure all terminal nodes are at the end
		let lastTerminalCprColFound: CprCol | undefined;
		for (const cprCol of this.cprCols) {
			cprCol.reset();

			if (!lastTerminalCprColFound || cprCol.isTermNode) {
				if (cprCol.isTermNode) {
					lastTerminalCprColFound = cprCol;
				}

				continue;
			}

			cprCol.addCriticalError(null,
				`${cprCol.cprNodeTypeId} cannot be after ${lastTerminalCprColFound.cprNodeTypeId}`
			);
		}

		if (canadaCics.length > 1) {
			this.errors.push(new CprError(CprErrorType.Error, CprSection.CprSectionCpr, 0, null,
				`Multiple Canada Carriers are not allowed.`
			));
		}
	}

	private invokeValidateListeners() {
		// invoke validate listeners
		// isArray check because sometimes this.validateListeners is not initialized
		if (Array.isArray(this.validateListeners)) {
			for (const validateListener of this.validateListeners) {
				validateListener();
			}
		}
	}

	private uniqueAndSorted(arr: string[]) {
		const setArr = new Set(arr);
		const unqArr = Array.from(setArr).sort();
		return unqArr.sort();
	}

	/**
	 * Labels not currently in use in the CPR
	 */
	private getUnusedLabels() {
		// Check for labels not in use
		const labelsInUse = new Set<CprLbl>();

		for (const cprRow of this.cprRows) {
			for (const cprCol of cprRow.getCprCols()) {
				const possibleLabel = cprCol.getCprLabel();

				if (possibleLabel) {
					labelsInUse.add(possibleLabel);
				}
			}
		}

		const unusedLabels: CprLbl[] = [];

		for (const cprLbl of this.cprLabels) {
			if (!labelsInUse.has(cprLbl)) {
				unusedLabels.push(cprLbl);
			}
		}

		return unusedLabels;
	}

}

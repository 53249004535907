/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/* prettier-ignore */


export const $RocIndexRequest = {
    properties: {
        entityId: {
            type: 'string',
        },
        respOrgId: {
            type: 'string',
        },
        rocTxnId: {
            type: 'string',
        },
        startTs: {
            type: 'Date',
            format: 'date-time',
        },
        endTs: {
            type: 'Date',
            format: 'date-time',
        },
        rocRequestTypeId: {
            type: 'number',
        },
        isCheckedOut: {
            type: 'number',
        },
        progressId: {
            type: 'number',
        },
        rejectReasonId: {
            type: 'number',
        },
        statusId: {
            type: 'number',
        },
        tfn: {
            type: 'string',
        },
        page: {
            type: 'number',
        },
        pageSize: {
            type: 'number',
            minimum: 1,
        },
        search: {
            type: 'string',
        },
    },
};